<template>
  <div class="dpi-layout-left-content index slide-in-left-enter">
    <div class="section-item">
      <div class="section">
        <p class="title">企业安全指数</p>
        <div class="content safe-content">
          <!-- <div class="safe-content-item safe-content-echarts" id="main1"></div> -->
          <div class="safe-content-item safe-content-echarts">
            <p>87<span>分</span></p>
          </div>
          <div class="safe-content-item safe-content-scores">
            <div class="safe-content-scores-item">
              <p>明日指数</p>
              <p><span>89</span>分</p>
            </div>
            <div class="safe-content-scores-item">
              <p>昨日指数</p>
              <p><span>85</span>分</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="section-item">
      <div class="section">
        <p class="title">企业状况</p>
        <div class="content company-content">
          <p>今日生产情况：生产装置<span>13</span>套，运行装置<span>0</span>套，停运装置<span>0</span>套。检修装置<span>0</span>套， 开车装置<span>0</span>套，停车装置<span>0</span>套，试生产装置<span>0</span>套，中（扩）试装置<span>0</span>套。 发包或者出租的项目<span>0</span>个，场所<span>0</span>处，设备<span>0</span>套。</p>
          <p>危险作业<span>25</span>处，其中，动火作业<span>0</span>处，受限空间<span>0</span>处，盲板抽堵<span>0</span>处，高处作业<span>1</span>处，吊装作业<span>3</span>处，临时用电<span>6</span>处，断路作业<span>9</span>处，动土作业<span>6</span>处。</p>
        </div>
      </div>
    </div>
    <div class="section-item">
      <div class="section">
        <p class="title">人员统计</p>
        <div class="content count-content">
          <div v-for="(item, index) in countArr" :key="index" class="item">
            <p>{{item.num}}</p>
            <p>{{item.title}}</p>
          </div>
        </div>
      </div>
    </div>
    <div class="section-item">
      <div class="section">
        <p class="title">今日巡检完成率</p>
        <div class="content">
          <div class="echarts-container" ref="today"></div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import * as echarts from 'echarts'
export default {
  name: 'DashboardLeft',
  data () {
    return {
      countArr: [
        {
          title: '总人数',
          num: 1320
        },
        {
          title: '生产区',
          num: 1000
        },
        {
          title: '办公区',
          num: 89
        },
        {
          title: '在岗',
          num: 111
        },
        {
          title: '不在岗',
          num: 20
        },
        {
          title: '承包商',
          num: 100
        }
      ],
      timer: null
    }
  },
  mounted () {
    this.$nextTick(() => {
      // this.init1()
      this.init2()
    })
  },
  beforeDestroy() {
    this.timer && clearInterval(this.timer)
  },
  methods: {
    init1 () {
      const gaugeData = [{
        value: 87,
        detail: {
          valueAnimation: true,
          offsetCenter: ['0%', '0%']
        }
      }]
      const option = {
        grid: {
          top: 0,
          bottom: 0
        },
        series: [
          {
            type: 'gauge',
            radius: '100%',
            startAngle: -90,
            endAngle: -450,
            pointer: {
              show: false
            },
            progress: {
              show: true,
              overlap: false,
              roundCap: true,
              clip: false,
              itemStyle: {
                borderWidth: 1,
                borderColor: '#464646'
              }
            },
            axisLine: {
              lineStyle: {
                width: 10
              }
            },
            splitLine: {
              show: false,
              distance: 0,
              length: 10
            },
            axisTick: {
              show: false
            },
            axisLabel: {
              show: false,
              distance: 50
            },
            data: gaugeData,
            title: {
              fontSize: 14
            },
            detail: {
              width: 50,
              height: 14,
              fontSize: 20,
              color: '#fff',
              formatter: '{value}分'
            }
          }]
      }
      // 绘制图表
      const echarts1 = echarts.init(document.getElementById('main1'))
      echarts1.setOption(option, true)

      window.addEventListener('resize', function () {
        echarts1.resize()
      })
    },
    init2 () {
      const option = {
        tooltip: {
          trigger: 'axis'
        },
        grid: {
          // left: '13%',
          top: 10,
          // bottom: 20
          // top: '0%',
          left: '0%',
          right: '0%',
          bottom: '0%',
          containLabel: true
        },
        xAxis: {
          type: 'category',
          data: ['水泥厂', '氯碱厂', '电石厂', '热电厂', '动力厂', '盐矿'],
          axisTick: {
            show: false,
            alignWithLabel: true
          },
          axisLabel: {
            color: '#A8CEFF',
            fontSize: '12px'
          }
        },
        yAxis: {
          type: 'value',
          min: 20,
          axisLabel: {
            color: '#A8CEFF',
            fontSize: '10px',
            formatter: '{value}%'
          },
          axisLine: {
            show: true
          },
          splitLine: {
            show: false
          }
        },
        series: {
          data: [80, 90, 90, 80, 70, 50],
          type: 'bar',
          barWidth: '10px',
          itemStyle: {
            borderRadius: [8, 8, 0, 0],
            color: 'rgba(10, 211, 250, 1)'
          }
        }
      }

      // 绘制图表
      const echarts2 = echarts.init(this.$refs.today)
      echarts2.setOption(option, true)

      window.addEventListener('resize', function () {
        echarts2.resize()
      })

      this.timer = setInterval(() => {
        this.refresh(echarts2)
      }, 2500)
    },
    refresh (echarts) {
      const option = echarts.getOption()
      const data = []
      for (var i = 0; i < 7; i++) {
        const temp = parseInt(Math.random() * 90)
        data.push(temp)
      }
      option.series[0].data = data
      echarts.setOption(option)
    }
  }
}
</script>
