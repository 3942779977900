import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const state = {
  navCurrent: 'dashboard',
  fullscreen: false
}

const getters = {
  navCurrent: state => state.navCurrent,
  fullscreen: state => state.fullscreen
}

const mutations = {
  SET_NAV_CURRENT(state, current) {
    localStorage.setItem('NAV_CURRENT', current)
    state.navCurrent = current
  },
  SET_FULLSCREEN(state, current) {
    state.fullscreen = current
  }
}

const actions = {}

export default new Vuex.Store({
  state,
  getters,
  mutations,
  actions
})