<template>
  <div class="dpi-layout-header">
    <div class="container">
      <!-- <div class="logo">
        <img src="~@/assets/logo3.png" alt="" style="position:absolute;top:12px;width:200px;left:10px;">
      </div> -->
      <ul>
        <template v-for="(item, index) in navList">
          <li v-if="item.label === 'left' || item.label === 'right'" :key="index" class="nav" :class="navCurrent === item.path ? 'current' : ''">
            <a :class="item.label === 'left' ? 'nav-left' : 'nav-right'">{{item.title}}</a>
          </li>
          <li v-else class="title" :key="index">{{item.title}}</li>
        </template>
      </ul>
      <div class="utils">
        <span class="yh-num" @click="$parent.dangerSources.visible = true"><span class="iconfont icon-baojing1" :class="active ? 'active' : ''"></span>13</span>
        <span class="setting"><span class="iconfont icon-shezhi"></span>设置</span>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapMutations } from 'vuex'
export default {
  name: 'SHeader',
  data () {
    return {
      active: false,
      timer: {
        time: '00:00:00',
        date: '1970-01-01',
        day: '星期一'
      },
      navList: [
        {
          path: 'dashboard',
          title: '数据总览',
          label: 'left'
        },
        {
          path: 'danger',
          title: '重大危险源',
          label: 'left'
        },
        {
          path: 'risk',
          title: '数据总览',
          label: 'left'
        },
        {
          path: 'check',
          title: '隐患排查',
          label: 'left'
        },
        {
          path: 'checkout',
          title: window.config.title,
          label: 'center'
        },
        {
          path: 'close',
          title: '视频监测',
          label: 'right'
        },
        {
          path: 'trouble',
          title: '电子巡检',
          label: 'right'
        },
        {
          path: 'work',
          title: '作业监督',
          label: 'right'
        },
        {
          path: 'group',
          title: '工艺组态',
          label: 'right'
        }
      ]
    }
  },
  computed: {
    ...mapGetters(['navCurrent'])
  },
  mounted () {
    setInterval(() => {
      // this.countDown()
      this.active = !this.active
    }, 500)
  },
  methods: {
    ...mapMutations({
      setNavCurrent: 'SET_NAV_CURRENT'
    }),
    checkTime (data) {
      return data > 9 ? data : `0${data}`
    },
    checkDay (day) {
      let text = ''
      switch (day) {
        case 0:
          text = '星期日'
          break
        case 1:
          text = '星期一'
          break
        case 2:
          text = '星期二'
          break
        case 3:
          text = '星期三'
          break
        case 4:
          text = '星期四'
          break
        case 5:
          text = '星期五'
          break
        case 6:
          text = '星期六'
          break
        default:
      }

      return text
    },
    countDown () {
      const date = new Date()
      const year = date.getFullYear()
      const month = date.getMonth() + 1
      const day = date.getDate()
      const hour = date.getHours()
      const minute = date.getMinutes()
      const second = date.getSeconds()
      const weekDay = date.getDay()

      this.timer.time = `${this.checkTime(hour)}:${this.checkTime(minute)}:${this.checkTime(second)}`
      this.timer.date = `${this.checkTime(year)}-${this.checkTime(month)}-${this.checkTime(day)}`
      this.timer.day = this.checkDay(weekDay)
    },
    handleClick (current) {
      this.setNavCurrent(current)
    }
  }
}
</script>
