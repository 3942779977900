<template>
    <div class="dpi-layout">
        <div class="dpi-layout-container">
            <div class="dpi-layout-mask"></div>
            <div id="amap"></div>
        </div>
        <DashboardHeader />
        <div class="dpi-layout-left" :style="{ left: !fullscreen ? '5px' : '-20%' }">
            <DashboardLeft />
        </div>

        <div class="dpi-layout-center" :style="centerStyle">
            <div class="dpi-layout-center-top" :style="centerStyleTop">
                <!-- 公共操作按钮 -->
                <div v-show="navCurrent !== 'group'" :class="['common-action-content', centerCommonClass]">
                    <div class="common-action" v-show="isOpen">
                        <span v-for="item in oprArr" :key="item.key"
                            :class="['iconfont', `icon-${item.icon}`, item.active ? 'active' : '']" :title="item.title"
                            @click="handleMenuClick(item.key, item.active)"></span>
                    </div>
                    <span v-show="isOpen" class="iconfont icon-zhedie3 icon-arrow" title="折叠"
                        @click="isOpen = false"></span>
                    <span v-show="!isOpen" class="iconfont icon-zhedie4 icon-arrow" title="展开"
                        @click="isOpen = true"></span>
                </div>
            </div>
            <div class="dpi-layout-center-bottom" :style="{ bottom: !fullscreen ? '0' : '-25%' }">
                <DashboardCenter />
            </div>
        </div>

        <div class="dpi-layout-right" :style="{ right: !fullscreen ? '5px' : '-20%' }">
            <DashboardRight />
        </div>
    </div>
</template>
   
<script>
import { mapGetters, mapMutations } from 'vuex'
import { buildings, personDetail, car, carDetail, camera } from '@/apis'
import { risk, riskData } from '@/apis/risk'
import { dangerSources, dangerSourcesPoint, dangerSourcesColumns, dangerSourcesData, qx, qxDetail } from '@/apis/danger'

import DashboardHeader from './Dashboard/header.vue'
import DashboardLeft from './Dashboard/left.vue'
import DashboardRight from './Dashboard/right.vue'
import DashboardCenter from './Dashboard/center.vue'

export default {
    name: "amap",
    components: {
        DashboardHeader,
        DashboardLeft,
        DashboardRight,
        DashboardCenter,
    },
    data() {
        return {
            isOpen: true,
            oprArr: [
                {
                    icon: 'xianshi',
                    title: '名称',
                    key: 'name',
                    active: false
                },
                {
                    icon: '041siyecao',
                    title: '四色图',
                    key: 'four',
                    active: false
                },
                {
                    icon: 'cheliangzhuapai',
                    title: '车辆',
                    key: 'car',
                    active: false
                },
                {
                    icon: 'renyuan',
                    title: '人员',
                    key: 'people',
                    active: false
                },
                {
                    icon: 'jiankong',
                    title: '监控',
                    key: 'monitor',
                    active: false
                },
                {
                    icon: 'quanping',
                    title: '全屏',
                    key: 'fullscreen',
                    active: this.fullscreen
                }
            ],
            map: null, //初始化 map 对象
            // 公司
            company: {
                name: null,
                site: null,
            },
            // 四色图
            fourColorPolygon: null,
            people: {
                markers: [],
                visible: false,
                detail: personDetail
            },
            car: {
                markers: [],
                visible: false,
                detail: carDetail
            },
            camera: {
                markers: null,
                visible: false
            },
            // 风险
            risk: {
                visible: false,
                data: riskData,
                names: null,
                markers: null,
                areas: null,
                detail: {
                    visible: false,
                    data: ''
                }
            },
            // 重大危险源
            dangerSources: {
                markers: null,
                names: null,
                areas: null,
                visible: false,
                title: '',
                data: dangerSourcesData,
                columns: dangerSourcesColumns,
                qx: null,
                qxNames: null,
                qxVisible: false,
                qxTitle: '',
                qxDetail
            },
        }
    },
    computed: {
        ...mapGetters(['navCurrent', 'fullscreen']),
        centerStyle() {
            if (this.fullscreen || this.navCurrent === 'group') {
                return {
                    width: '100%',
                    left: 0,
                    zIndex: this.navCurrent === 'group' ? 110 : 'auto'
                }
            } else {
                return {
                    width: 'calc(60% - 10px)',
                    left: 'calc(20% + 5px)'
                }
            }
        },
        centerCommonClass() {
            if (this.fullscreen) {
                return ''
            } else {
                return this.navCurrent === 'dashboard' ? 'bottom' : ''
            }
        },
        centerStyleTop() {
            if (this.fullscreen) {
                return {
                    height: '100%'
                }
            } else {
                if (this.navCurrent === 'dashboard') {
                    return {
                        height: '75%'
                    }
                } else if (this.navCurrent === 'danger') {
                    return {
                        height: 'calc(100% - 65px)'
                    }
                } else {
                    return {
                        height: '100%'
                    }
                }
            }
        }
    },
    mounted() {
        //DOM初始化完成进行地图初始化
        this.initMap();
        this.showDangerSources()
    },
    methods: {
        ...mapMutations({
            setFullscreen: 'SET_FULLSCREEN'
        }),
        initMap() {
            this.tileLayer = new AMap.TileLayer({
                getTileUrl: 'tiles/2D/[z]/tile-[x]_[y].png',
                opacity: 0.8,
                zIndex: 100
            })
            this.map = new AMap.Map("amap", { //设置地图容器id
                zoom: 15, //初始化地图级别
                zooms: [15, 19],
                center: [117.262023925781, 32.5841217041016], //初始化地图中心点位置,
                showLabel: false, //不显示地图文字标记,
                layers: [new AMap.TileLayer.Satellite(),this.tileLayer],
            });
            this.map.on('click', e => {
                console.log(this.map.getZoom())
            })
        },
        handleMenuClick(type, active) {
            this.handleMenuActive(type, !active)
            switch (type) {
                case '3D':
                    this.handleTiles(active)
                    break
                case 'name':
                    !active ? this.showCompany() : this.removeMarkers([this.company.name, this.company.site])
                    break
                case 'four':
                    !active ? this.showFourColorInMap() : this.removeMarkers([this.fourColorPolygon])
                    break
                case 'car':
                    !active ? this.showCarInMap() : this.removeMarkers([this.car.markers])
                    break
                case 'people':
                    !active ? this.showPeopleInMap() : this.removeMarkers([this.people.markers])
                    break
                case 'monitor':
                    !active ? this.showMonitorInMap() : this.removeMarkers([this.camera.markers])
                    break
                case 'fullscreen':
                    this.setFullscreen(!active)
                    break
                default:
            }
        },
        // *************** 事件处理 **************
        handleMenuActive(type, active) {
            const temp = this.oprArr.map(item => {
                if (item.key === type) {
                    return {
                        ...item,
                        active
                    }
                }
                return item
            })

            this.oprArr = temp
        },
        // 删除地图上的覆盖物
        removeMarkers(markers) {
            markers.forEach(item => {
                this.map.remove(item)
            })
        },
        // 重大危险源
        showDangerSources() {
            const polygonArr = []
            for (const item of dangerSources) {
                const path = []
                for (const t of item) {
                    path.push([t.R, t.Q])
                }
                const polygon = {
                    path,
                    strokeColor: '#ff0000',
                    strokeWeight: 2,
                    strokeOpacity: 1,
                    strokeStyle: 'dashed',
                    fillOpacity: 0,
                    fillColor: 'transparent',
                    zIndex: 102,
                    bubble: true
                }
                polygonArr.push(polygon)
            }

            this.dangerSources.areas = this.initPolygon(polygonArr)
            this.dangerSources.markers = this.initClusterMarker(dangerSourcesPoint, 'dangerSourcesMarkers')

            const textPos = dangerSourcesPoint.map((item, index) => {
                return {
                    text: item.text,
                    position: [item.position.R, item.position.Q],
                    anchor: 'center', // 设置文本标记锚点
                    cursor: 'pointer',
                    offset: new AMap.Pixel(0, -45),
                    zIndex: 102,
                    style: {
                        'margin-bottom': '20px',
                        'border-width': 0,
                        'padding': '3px 5px',
                        'border-radius': '2px',
                        'background-color': 'rgba(0, 0, 0, 0.5)',
                        'font-size': '12px',
                        'color': '#fff'
                    }
                }
            })

            this.dangerSources.names = this.initText(textPos)
        },
        // 名称
        showCompany() {
            const name = []
            const site = []
            buildings.forEach(item => {
                name.push(item.text)
                const path = []
                for (const t of item.polygon) {
                    path.push([t.R, t.Q])
                }
                const polygon = {
                    path,
                    strokeColor: '#5fa6fd',
                    strokeWeight: 1,
                    strokeOpacity: 1,
                    strokeStyle: 'dashed',
                    fillOpacity: 0.3,
                    fillColor: '#0066e4',
                    bubble: true
                }
                site.push(polygon)
            })
            this.company.name = this.initText(name)
            this.company.site = this.initPolygon(site)
        },
        // 四色图
        showFourColorInMap() {
            const four = ['#ff0006', '#ff9600', '#ffd200', '#0084ff']
            const fourColor = []
            buildings.forEach(item => {
                const path = []
                for (const t of item.polygon) {
                    path.push([t.R, t.Q])
                }
                const polygon = {
                    path,
                    // strokeColor: '#5fa6fd',
                    // strokeOpacity: 1,
                    // strokeStyle: 'dashed',
                    // bubble: true,
                    strokeWeight: 0,
                    fillOpacity: 0.5,
                    fillColor: four[parseInt(Math.random() * 4)]
                }
                fourColor.push(polygon)
            })
            this.fourColorPolygon = this.initPolygon(fourColor)
        },
        // 车辆
        showCarInMap() {
            for (const position of car) {
                const marker = new AMap.Marker({
                    map: this.map,
                    position: position[0],
                    icon: new AMap.Icon({
                        size: new AMap.Size(30, 30),
                        image: 'static/car.png',
                        imageSize: new AMap.Size(30, 30),
                        imageOffset: new AMap.Pixel(0, 0)
                    })
                })
                // marker.on('click', e => {
                //     this.handleClickMarker(e, marker, 'car')
                // })
                marker.moveAlong(position, 200)
                this.car.markers.push(marker)
            }
        },
        // 人员
        showPeopleInMap() {
            for (const position of car) {
                const marker = new AMap.Marker({
                    map: this.map,
                    position: position[0],
                    icon: new AMap.Icon({
                        size: new AMap.Size(30, 30),
                        image: 'static/people.png',
                        imageSize: new AMap.Size(30, 30),
                        imageOffset: new AMap.Pixel(0, 0)
                    })
                })
                // marker.on('click', e => {
                //     this.handleClickMarker(e, marker, 'people')
                // })
                marker.moveAlong(position, 100)
                this.people.markers.push(marker)
            }
        },
        // 摄像头
        showMonitorInMap() {
            const arr = []
            camera.forEach(position => {
                arr.push({
                    position,
                    icon: new AMap.Icon({
                        size: new AMap.Size(30, 30),
                        image: 'static/camera.png',
                        imageSize: new AMap.Size(30, 30),
                        imageOffset: new AMap.Pixel(0, 0)
                    })
                })
            })
            this.camera.markers = this.initClusterMarker(arr, 'camera')
        },
        // 多边形
        initPolygon(data) {
            const polygons = []
            for (const item of data) {
                polygons.push(new AMap.Polygon(item))
            }
            const target = new AMap.OverlayGroup(polygons)
            this.map.add(target)
            return target
        },
        // 文本
        initText(data) {
            const texts = []
            for (const item of data) {
                texts.push(new AMap.Text(item))
            }
            const target = new AMap.OverlayGroup(texts)
            this.map.add(target)
            return target
        },
        // 聚合marker
        initClusterMarker(data, type, opts = { gridSize: 10 }) {
            const markers = []
            for (const item of data) {
                const marker = new AMap.Marker(item)
                // 给标记点增加点击事件
                // marker.on('click', e => {
                //     this.handleClickMarker(e, marker, type)
                // })
                markers.push(marker)
            }
            // 聚合函数
            return new AMap.MarkerClusterer(this.map, markers, opts)
        },
    },
}
</script>
   
<style>
@import '../style/index.css';
</style>