<template>
  <div class="section-item slide-in-up-enter">
    <div class="section">
      <p class="title">隐患排查与治理</p>
      <div class="content">
        <div class="echarts-container" id="main3"></div>
        <div class="echarts-container" id="main4"></div>
      </div>
    </div>
  </div>
</template>
<script>
import * as echarts from 'echarts'
import { mapGetters } from 'vuex'
export default {
  name: 'DashboardCenter',
  data() {
    return {
      timer1: null,
      timer2: null,
      echarts3: null,
      echarts4: null
    }
  },
  watch: {
    // fullscreen() {
    //   this.echarts3.resize()
    //   this.echarts4.resize()
    // }
  },
  computed: {
    ...mapGetters(['fullscreen']),
  },
  mounted () {
    this.$nextTick(() => {
      this.init3()
      this.init4()
    })
  },
  beforeDestroy() {
    this.timer1 && clearInterval(this.timer1)
    this.timer2 && clearInterval(this.timer2)
  },
  methods: {
    init3 () {
      const option = {
        tooltip: {
          trigger: 'axis'
          // formatter: function(params) {
          //   let str = params[0].data[0] + "<br />";
          //   params.forEach((item) => {
          //     str += '<span style="display:inline-block;margin-right:5px;border-radius:50%;width:10px;height:10px;left:5px;background-color:' + item.color + '"></span>' + item.seriesName + " : " + item.data[1] + "<br />";
          //   });
          //   return str;
          // }
        },
        grid: {
          top: 5,
          left: 25,
          right: 35,
          bottom: 20
        },
        xAxis: {
          type: 'category',
          data: ['一月', '二月', '三月', '四月', '五月', '六月', '七月', '八月', '九月', '十月', '十一月', '十二月'],
          axisTick: {
            show: true,
            alignWithLabel: true
          },
          axisLabel: {
            color: '#A8CEFF'
          }
        },
        yAxis: [
          {
            type: 'value',
            min: 0,
            axisLabel: {
              color: '#A8CEFF',
              fontSize: '10px'
            },
            splitLine: {
              lineStyle: {
                color: ['#17266A']
              }
            }
          },
          {
            type: 'value',
            min: 0,
            axisLabel: {
              color: '#A8CEFF',
              fontSize: '10px',
              formatter: '{value}%'
            },
            splitLine: {
              lineStyle: {
                color: ['#17266A']
              }
            }
          }
        ],
        series: [{
          data: [80, 100, 90, 80, 70, 20, 10, 30, 40, 50, 60, 90],
          name: '隐患数',
          type: 'bar',
          barWidth: '15px',
          yAxisIndex: 0,
          itemStyle: {
            color: '#0190FF'
          }
        },
        {
          data: [80, 100, 90, 80, 70, 20, 10, 30, 40, 50, 60, 90],
          name: '整改率',
          type: 'line',
          yAxisIndex: 1,
          itemStyle: {
            color: '#75F401'
          }
        }
        ]
      }

      // 绘制图表
      this.echarts3 = echarts.init(document.getElementById('main3'))
      this.echarts3.setOption(option, true)

      window.addEventListener('resize',  ()=> {
        this.echarts3.resize()
      })

      this.timer1 = setInterval(() => {
        this.refresh(this.echarts3, 12)
      }, 2500)
    },
    init4 () {
      const opts = {
        // title: {
        //   text: 'Referer of a Website',
        //   subtext: 'Fake Data',
        //   left: 'center'
        // },
        center: ['100%', '100%'],
        tooltip: {
          trigger: 'item'
        },
        // legend: {
        //   orient: 'vertical',
        //   left: 'left'
        // },
        grid: {
          top: 10,
          bottom: 100
        },
        series: [{
          name: '隐患占比',
          type: 'pie',
          radius: ['40%', '70%'],
          data: [
            { value: 40, name: '水泥厂' },
            { value: 21, name: '氯碱厂' },
            { value: 17, name: '电石厂' },
            { value: 13, name: '热电厂' },
            { value: 9, name: '动力厂' },
            { value: 20, name: '盐矿' }
          ],
          label: {
            color: '#A8CEFF',
            formatter: '{b}\n{d}' + '%',
          }
        }]
      }

      this.echarts4 = echarts.init(document.getElementById('main4'))
      this.echarts4.setOption(opts, true)

      window.addEventListener('resize', ()=> {
        this.echarts4.resize()
      })

      this.timer2 = setInterval(() => {
        this.refresh(this.echarts4, 6)
      }, 2500)
    },
    refresh (echarst, len) {
      const option = echarst.getOption()
      const data = []
      const data1 = []
      if (len === 12) {
        for (let i = 0; i < len; i++) {
          const temp = parseInt(Math.random() * 100)
          data.push(temp)
        }
        option.series[0].data = data
        for (let i = 0; i < len; i++) {
          const temp = parseInt(Math.random() * 100)
          data1.push(temp)
        }
        option.series[1].data = data1
      } else {
        const arr = ['水泥厂', '氯碱厂', '电石厂', '热电厂', '动力厂', '盐矿']
        for (let i = 0; i < len; i++) {
          const temp = parseInt(Math.random() * 100)
          data.push({
            value: temp,
            name: arr[i]
          })
        }
        option.series[0].data = data
      }
      echarst.setOption(option)
    }
  }
}
</script>
