<template>
  <div class="dpi-layout-right-content index slide-in-right-enter">
    <div class="section-item">
      <div class="section">
        <p class="title">实时统计</p>
        <div class="content static-content">
          <div v-for="(item, index) in dataArr" :key="index" class="item" :class="`item${index+1}`">
            <p v-if="item.num2"><span>{{item.num1}}/</span><span>{{item.num2}}</span></p>
            <p class="num1" v-else>{{item.num1}}</p>
            <p>{{item.title}}</p>
          </div>
        </div>
      </div>
    </div>
    <div class="section-item">
      <div class="section">
        <p class="title">重大危险源数量</p>
        <div class="content safe-number-content">
          <template v-for="(item, index) in safeArr">
            <div class="item-section" :key="index">
              <div class="item">
                <div class="item-echarts" :id="`safe${index + 1}`"></div>
                <p>{{item.title}}</p>
              </div>
            </div>
          </template>
        </div>
      </div>
    </div>
    <div class="section-item">
      <div class="section">
        <p class="title">风险点位统计</p>
        <div class="content">
          <div class="echarts-container" id="main5"></div>
        </div>
      </div>
    </div>
    <div class="section-item">
      <div class="section">
        <p class="title">可燃/有毒气体统计</p>
        <div class="content gas-content">
          <div v-for="(item, index) in gasArr" :key="index" class="item">
            <span class="iconfont" :class="`icon-${item.icon}`"></span>
            <div class="gas-item">
              <p>{{item.num}}</p>
              <p>{{item.title}}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import * as echarts from 'echarts'
export default {
  name: 'DashboardRight',
  data () {
    return {
      timer: null,
      dataArr: [
        {
          title: '预警/响应',
          num1: '6',
          num2: '4'
        },
        {
          title: '隐患/整改',
          num1: '8',
          num2: '8'
        },
        {
          title: '重大隐患数',
          num1: '5',
          num2: null
        },
        {
          title: '一般隐患数',
          num1: '3',
          num2: null
        }
      ],
      safeArr: [
        {
          title: '重大',
          value: 2713,
          color: '#00CBFF'
        },
        {
          title: '较大',
          value: 1099,
          color: '#F85D41'
        },
        {
          title: '一般',
          value: 1022,
          color: '#7FC43C'
        },
        {
          title: '极低',
          value: 320,
          color: '#6D62F2'
        }
      ],
      gasArr: [
        {
          icon: 'jianzhuanquan-',
          title: '可燃气体',
          num: '180处'
        },
        {
          icon: 'weichuligaojing',
          title: '未处理报警',
          num: '2条'
        },
        {
          icon: 'yichuligaojing',
          title: '已处理报警',
          num: '40条'
        },
        {
          icon: '-yiliao-bingdu',
          title: '有毒气体',
          num: '98处'
        },
        {
          icon: 'weichuligaojing',
          title: '未处理报警',
          num: '8条'
        },
        {
          icon: 'yichuligaojing',
          title: '已处理报警',
          num: '120条'
        }
      ]
    }
  },
  beforeDestroy () {
    this.timer && clearInterval(this.timer)
  },
  mounted () {
    setTimeout(() => {
      this.init1()
      this.init2()
    }, 500)
  },
  methods: {
    init1 () {
      const total = this.safeArr.map(item => item.value).reduce((total, next) => total + next, 0)
      const option = {
        series: [{
          type: 'gauge',
          startAngle: 180,
          endAngle: 0,
          min: 0,
          max: total,
          splitNumber: 12,
          itemStyle: {
            color: '#58D9F9',
            shadowColor: 'rgba(0,138,255,0.45)',
            shadowBlur: 10,
            shadowOffsetX: 2,
            shadowOffsetY: 2
          },
          progress: {
            show: true,
            roundCap: true,
            width: 5
          },
          pointer: false,
          axisLine: {
            roundCap: true,
            lineStyle: {
              width: 5
            }
          },
          axisTick: false,
          splitLine: false,
          axisLabel: false,
          title: {
            show: false
          },
          detail: {
            borderWidth: 0,
            width: '10%',
            offsetCenter: [0, '50%'],
            valueAnimation: true,
            formatter: function (value) {
              return '{value|' + value.toFixed(0) + '}'
            },
            rich: {
              value: {
                fontSize: 12,
                color: '#A8CEFF'
              }
            }
          },
          data: [{
            value: 100
          }]
        }]
      }

      this.safeArr.forEach((item, index) => {
        // 绘制图表
        const dom = document.getElementById(`safe${index + 1}`)
        option.series[0].data[0].value = item.value
        option.series[0].itemStyle.color = item.color
        const echarts0 = echarts.init(dom)
        echarts0.setOption(option, true)

        window.addEventListener('resize', function () {
          echarts0.resize()
        })
      })
    },
    init2 () {
      const option = {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            // Use axis to trigger tooltip
            type: 'shadow' // 'shadow' as default; can also be 'line' or 'shadow'
          }
        },
        // legend: {},
        grid: {
          left: '22%',
          top: 0,
          bottom: 0
        },
        xAxis: {
          type: 'value',
          show: false
        },
        yAxis: {
          type: 'category',
          data: ['环境因素', '人的因素', '物的因素', '检修', '设备设施', '作业活动'],
          axisTick: {
            show: false,
            alignWithLabel: false
          },
          axisLabel: {
            color: '#A8CEFF',
            fontSize: 12
          }
        },
        series: [{
          name: '1级风险点',
          type: 'bar',
          stack: 'total',
          barWidth: '10px',
          // label: {
          //   show: true
          // },
          emphasis: {
            focus: 'series'
          },
          data: [320, 302, 301, 334, 390, 330]
        },
        {
          name: '2级风险点',
          type: 'bar',
          stack: 'total',
          barWidth: '10px',
          // label: {
          //   show: true
          // },
          emphasis: {
            focus: 'series'
          },
          data: [120, 132, 101, 134, 90, 100]
        },
        {
          name: '3级风险点',
          type: 'bar',
          stack: 'total',
          barWidth: '10px',
          // label: {
          //   show: true
          // },
          emphasis: {
            focus: 'series'
          },
          data: [220, 182, 191, 234, 290, 200]
        },
        {
          name: '4级风险点',
          type: 'bar',
          stack: 'total',
          barWidth: '10px',
          // label: {
          //   show: true
          // },
          emphasis: {
            focus: 'series'
          },
          data: [150, 212, 201, 154, 190, 400]
        }
        ]
      }

      // 绘制图表
      const echarts5 = echarts.init(document.getElementById('main5'))
      echarts5.setOption(option, true)

      window.addEventListener('resize', function () {
        echarts5.resize()
      })

      this.timer = setInterval(() => {
        this.refresh(echarts5)
      }, 2500)
    },
    refresh (echarst) {
      const option = echarst.getOption()
      const data = []
      for (var i = 0; i < 6; i++) {
        const temp = parseInt(Math.random() * 1000 * (i + 1))
        data.push(temp)
      }
      option.series[0].data = data
      echarst.setOption(option)
    }
  }
}
</script>
