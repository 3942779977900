import { mergeColumns } from "@/utils"

// 风险
export const risk = [
  {
    type: 1,
    name: '乙炔厂',
    area: [{"Q":32.5870506601744,"R":117.26002836227417,"lng":117.260028,"lat":32.587051},{"Q":32.58482683198828,"R":117.2586121559143,"lng":117.258612,"lat":32.584827},{"Q":32.58696026173041,"R":117.25404167175293,"lng":117.254042,"lat":32.58696},{"Q":32.58909364069581,"R":117.2554578781128,"lng":117.255458,"lat":32.589094}],
    marker: [117.258006,32.582675]
  },
  {
    type: 1,
    name: '电石厂',
    area: [{"Q":32.59328793263263,"R":117.25831174850464,"lng":117.258312,"lat":32.593288},{"Q":32.58974449196615,"R":117.26590776443481,"lng":117.265908,"lat":32.589744},{"Q":32.58562235410003,"R":117.2632040977478,"lng":117.263204,"lat":32.585622},{"Q":32.58929251241874,"R":117.25558662414551,"lng":117.255587,"lat":32.589293}],
    marker: [117.254149,32.584275]
  },
  {
    type: 1,
    name: 'PVC',
    area: [{"Q":32.58922019547964,"R":117.26704502105713,"lng":117.267045,"lat":32.58922},{"Q":32.58806311651853,"R":117.26949119567871,"lng":117.269491,"lat":32.588063},{"Q":32.58580315359538,"R":117.26801061630249,"lng":117.268011,"lat":32.585803},{"Q":32.58688794290957,"R":117.26552152633667,"lng":117.265522,"lat":32.586888}],
    marker: [117.256831,32.587114]
  },
  {
    type: 1,
    name: '烧碱厂',
    area: [{"Q":32.58665290633888,"R":117.27028512954712,"lng":117.270285,"lat":32.586653},{"Q":32.58493531269197,"R":117.27395439147949,"lng":117.273954,"lat":32.584935},{"Q":32.58153618825876,"R":117.27178716659546,"lng":117.271787,"lat":32.581536},{"Q":32.58323576658554,"R":117.26811790466309,"lng":117.268118,"lat":32.583236}],
    marker: [117.260543,32.589338]
  },
  {
    type: 1,
    name: 'VCM',
    area: [{"Q":32.58527883405419,"R":117.26363325119019,"lng":117.263633,"lat":32.585279},{"Q":32.58327192747648,"R":117.2679033279419,"lng":117.267903,"lat":32.583272},{"Q":32.58146386506209,"R":117.2667875289917,"lng":117.266788,"lat":32.581464},{"Q":32.58343465130518,"R":117.26247453689575,"lng":117.262475,"lat":32.583435}],
    marker: [117.266895,32.587638]
  },
  {
    type: 1,
    name: '机械动力厂',
    area: [{"Q":32.58209669105494,"R":117.26363325119019,"lng":117.263633,"lat":32.582097},{"Q":32.57916757323518,"R":117.27011346817017,"lng":117.270113,"lat":32.579168},{"Q":32.577630652151775,"R":117.26912641525269,"lng":117.269126,"lat":32.577631},{"Q":32.58063214410685,"R":117.26258182525635,"lng":117.262582,"lat":32.580632}],
    marker: [117.270886,32.584185]
  },
  {
    type: 1,
    name: '热电厂',
    area: [{"Q":32.58529691408944,"R":117.25687408447266,"lng":117.256874,"lat":32.585297},{"Q":32.5831272838252,"R":117.26146602630615,"lng":117.261466,"lat":32.583127},{"Q":32.579710009680774,"R":117.25919151306152,"lng":117.259192,"lat":32.57971},{"Q":32.58193396479769,"R":117.25459957122803,"lng":117.2546,"lat":32.581934}],
    marker: [117.265221,32.583245]
  },
  {
    type: 1,
    name: '水泥厂',
    area: [{"Q":32.586580587270056,"R":117.25365543365479,"lng":117.253655,"lat":32.586581},{"Q":32.58524267397279,"R":117.25655221939087,"lng":117.256552,"lat":32.585243},{"Q":32.58202436831039,"R":117.25427770614624,"lng":117.254278,"lat":32.582024},{"Q":32.58325384703283,"R":117.25153112411499,"lng":117.251531,"lat":32.583254}],
    marker: [117.266723,32.579719]
  },
  {
    type: 2,
    name: '苯加氢装置区',
    area: [{"Q":32.586580587270056,"R":117.25365543365479,"lng":117.253655,"lat":32.586581},{"Q":32.58524267397279,"R":117.25655221939087,"lng":117.256552,"lat":32.585243},{"Q":32.58202436831039,"R":117.25427770614624,"lng":117.254278,"lat":32.582024},{"Q":32.58325384703283,"R":117.25153112411499,"lng":117.251531,"lat":32.583254}],
    marker: [117.254149,32.584275]
  },
  {
    type: 3,
    name: '苯加氢装置区',
    marker: [117.254371,32.584547]
  },
  {
    type: 3,
    name: '储罐装置区',
    marker: [117.259902,32.588845]
  },
  {
    type: 3,
    name: '汽车装卸区',
    marker: [117.26936,32.58536]
  },
  {
    type: 3,
    name: '制氮装置区',
    marker: [117.266205,32.584755]
  },
  {
    type: 3,
    name: '制冷装置区',
    marker: [117.265655,32.57946]
  },
  {
    type: 4,
    name: '加氢单元',
    marker: [117.254723,32.583786]
  },
  {
    type: 4,
    name: '抽提单元',
    marker: [117.257405,32.583546]
  },
  {
    type: 4,
    name: '精馏单元',
    marker: [117.26065,32.587971]
  },
  {
    type: 4,
    name: '粗苯罐区',
    marker: [117.257866,32.586543]
  },
  {
    type: 4,
    name: '粗苯改造罐区',
    marker: [117.267481,32.587284]
  },
  {
    type: 4,
    name: '产品罐区',
    marker: [117.267459,32.584961]
  },
  {
    type: 4,
    name: '汽车装卸单元',
    marker: [117.271611,32.585354]
  },
  {
    type: 4,
    name: '制氮单元',
    marker: [117.270871,32.583085]
  },
  {
    type: 4,
    name: '制冷单元',
    marker: [117.270018,32.580961]
  },
  {
    type: 4,
    name: '制氯单元',
    marker: [117.262841,32.581494]
  },
  {
    type: 4,
    name: '制氢单元',
    marker: [117.266955,32.579487]
  },
]

const temp1 = {
  "departmentName": "甲醇分公司（一期）",
  "processName": "一期脱硫转化装置",
  "riskUnitName": "一期脱硫单元",
  "riskLevel": "二级",
  "riskEventName": "一期脱硫单元泄漏造成火灾、爆炸",
  "riskDesc" : "主要存在的危险化学品介质为H2、CO、CH4等有毒、有害物质，其均为易燃易爆介质。                                潜在的主要风险有：                    \n1.如果气柜高度仪失效或自调阀门失灵导致气柜超高，破水封，造成煤气泄漏，现场人员未穿戴防护用品，可能会导致现场人员煤气中毒；                                    \n2.如果煤气中氧含量超标可能会导致加氢反应槽超温，从而发生物理爆炸，同时导致大量易燃易爆高温物料泄漏发生自燃爆炸；                          \n3.如果煤气管线、法兰、阀门等位置因冲刷、老化、腐蚀等原因出现漏点，导致煤气泄漏，现场人员未穿戴防护用品，可能会导致现场人员煤气中毒，遇点火源可能造成火灾、爆炸。                                \n",
  "controlLevel": "部门级",
  "status": "异常"
}

const riskDataTemp1 = [
  {
    "id": "30575588-fe31-4742-80d9-b49b74c43f45",
    "measureType1": "1",
    "measureTypeOneName": "工程技术措施",
    "measureType2": "6",
    "measureTypeTwoName": "关键设备",
    "measureType3": "铁钼加氢反应器",
    "measureText": "1.铁钼加氢反应器设置有高高温度SIS联锁，联锁号01TSHH-002/004/007/008/009，联锁指标R101A/B床层温度（01TI002/01TI004)>440℃、R102床层温度（01TI007/01TI008)>435℃、R102出口温度（01TI009）>435℃中有三个及以上符合，系统停车；\n2.钴钼加氢反应器设置高高温度SIS联锁，联锁号01TSHH-021/022/023/026，联锁指标R104床层温度（01TI021/01TI022）>450℃、R104出口温度（011TI023/01TI029）>450℃中有两个及以上符合，系统停车。\n\n\n\n",
    "hiddenContent": [
      "1.一级加氢反应器床层温度是否<440℃，二级加氢反应器床层温度及出口温度是否<435℃；",
      "2.钴钼加氢反应器床层及出口温度是否<450℃。"
    ],
    "hiddenContent1": "[\"1.一级加氢反应器床层温度是否<440℃，二级加氢反应器床层温度及出口温度是否<435℃；\",\"2.钴钼加氢反应器床层及出口温度是否<450℃。\"]",
    "positionNo": "R102A/B",
    "principalUuid": null,
    "principalName": null,
    "checkCycle": null,
    "cycleUnit": null
  },
  {
    "id": "3aa94ac3-0cd1-465c-a993-84729616640a",
    "measureType1": "4",
    "measureTypeOneName": "应急措施",
    "measureType2": "17",
    "measureTypeTwoName": "消防设施",
    "measureType3": "-",
    "measureText": "编制有《气柜泄漏着火处置方案》，每半年各班组分别对方案演练一次。",
    "hiddenContent": [
      "1.是否编制有《气柜泄漏着火处置方案》，是否发放到有关岗位；",
      "2.是否每半年至少组织一次现场处置方案定演练并建立记录。"
    ],
    "hiddenContent1": "[\"1.是否编制有《气柜泄漏着火处置方案》，是否发放到有关岗位；\",\"2.是否每半年至少组织一次现场处置方案定演练并建立记录。\"]",
    "positionNo": "-",
    "principalUuid": null,
    "principalName": null,
    "checkCycle": null,
    "cycleUnit": null
  },
  {
    "id": "454d4660-8350-419d-af6c-4c10fd018ab9",
    "measureType1": "1",
    "measureTypeOneName": "工程技术措施",
    "measureType2": "6",
    "measureTypeTwoName": "关键设备",
    "measureType3": "铁钼预加氢反应器",
    "measureText": "1.铁钼加氢反应器设置有高高温度SIS联锁，联锁号01TSHH-002/004/007/008/009，联锁指标R101A/B床层温度（01TI002/01TI004)>440℃、R102床层温度（01TI007/01TI008)>435℃、R102出口温度（01TI009）>435℃中有三个及以上符合，系统停车；\n2.钴钼加氢反应器设置高高温度SIS联锁，联锁号01TSHH-021/022/023/026，联锁指标R104床层温度（01TI021/01TI022）>450℃、R104出口温度（011TI023/01TI029）>450℃中有两个及以上符合，系统停车。\n\n\n\n",
    "hiddenContent": [
      "1.一级加氢反应器床层温度是否<440℃，二级加氢反应器床层温度及出口温度是否<435℃；",
      "2.钴钼加氢反应器床层及出口温度是否<450℃。"
    ],
    "hiddenContent1": "[\"1.一级加氢反应器床层温度是否<440℃，二级加氢反应器床层温度及出口温度是否<435℃；\",\"2.钴钼加氢反应器床层及出口温度是否<450℃。\"]",
    "positionNo": "R101A/B",
    "principalUuid": null,
    "principalName": null,
    "checkCycle": null,
    "cycleUnit": null
  },
  {
    "id": "45b15012-615c-433e-ab11-df2eb06acc3e",
    "measureType1": "2",
    "measureTypeOneName": "维护保养",
    "measureType2": "10",
    "measureTypeTwoName": "动设备",
    "measureType3": "-",
    "measureText": "备用机泵每周进行盘车，定期进行计划性保养。",
    "hiddenContent": [
      "1.备用机泵是否每周进行盘车，是否建立记录；",
      "2.备用机泵润滑油、防护设施等是否正常；",
      "3.运行设备是否有异音、振动是否异常，机封、进出口法兰是否泄漏。"
    ],
    "hiddenContent1": "[\"1.备用机泵是否每周进行盘车，是否建立记录；\",\"2.备用机泵润滑油、防护设施等是否正常；\",\"3.运行设备是否有异音、振动是否异常，机封、进出口法兰是否泄漏。\"]",
    "positionNo": "-",
    "principalUuid": null,
    "principalName": null,
    "checkCycle": null,
    "cycleUnit": null
  },
  {
    "id": "574506bb-10d6-4ca6-b09b-7a1a2da7f3f6",
    "measureType1": "2",
    "measureTypeOneName": "维护保养",
    "measureType2": "11",
    "measureTypeTwoName": "静设备",
    "measureType3": "-",
    "measureText": "对静设备定期进行设备检查检测。",
    "hiddenContent": [
      "静设备是否定期检查、检测，是否建立记录。"
    ],
    "hiddenContent1": "[\"静设备是否定期检查、检测，是否建立记录。\"]",
    "positionNo": "-",
    "principalUuid": null,
    "principalName": null,
    "checkCycle": null,
    "cycleUnit": null
  },
  {
    "id": "584f1cf8-7e74-419e-bb15-c8cdfe1cb0be",
    "measureType1": "4",
    "measureTypeOneName": "应急措施",
    "measureType2": "16",
    "measureTypeTwoName": "个体防护",
    "measureType3": "-",
    "measureText": "按岗位风险，配备过滤式防毒面具、防护面罩、耳塞等防护用品。",
    "hiddenContent": [
      "1.岗位人员进入现场是否正确佩戴安全防护用品；",
      "2.个人安全防护用品是否完好、在有效期内。"
    ],
    "hiddenContent1": "[\"1.岗位人员进入现场是否正确佩戴安全防护用品；\",\"2.个人安全防护用品是否完好、在有效期内。\"]",
    "positionNo": "-",
    "principalUuid": null,
    "principalName": null,
    "checkCycle": null,
    "cycleUnit": null
  },
  {
    "id": "5e117ce9-65ca-474f-a3f0-9b7e4668a3cf",
    "measureType1": "4",
    "measureTypeOneName": "应急措施",
    "measureType2": "17",
    "measureTypeTwoName": "消防设施",
    "measureType3": "-",
    "measureText": "设置有消防栓、干粉灭火器等。",
    "hiddenContent": [
      "1.消防水炮、消防栓、干粉灭火器等是否完好投用；",
      "2.灭火器是否定期检查并建立记录。"
    ],
    "hiddenContent1": "[\"1.消防水炮、消防栓、干粉灭火器等是否完好投用；\",\"2.灭火器是否定期检查并建立记录。\"]",
    "positionNo": "-",
    "principalUuid": null,
    "principalName": null,
    "checkCycle": null,
    "cycleUnit": null
  },
  {
    "id": "6159dfa9-0ca6-4023-83c5-d0ebc2c8c647",
    "measureType1": "1",
    "measureTypeOneName": "工程技术措施",
    "measureType2": "9",
    "measureTypeTwoName": "其他",
    "measureType3": "视频监控",
    "measureText": "高清视屏监控设施时时监测现场。",
    "hiddenContent": [
      "1.视频是否完好；",
      "2.关键设备是否全覆盖监控。"
    ],
    "hiddenContent1": "[\"1.视频是否完好；\",\"2.关键设备是否全覆盖监控。\"]",
    "positionNo": "-",
    "principalUuid": null,
    "principalName": null,
    "checkCycle": null,
    "cycleUnit": null
  },
  {
    "id": "6de69ebd-7a1a-4869-9998-cc1cccd6c8ac",
    "measureType1": "1",
    "measureTypeOneName": "工程技术措施",
    "measureType2": "7",
    "measureTypeTwoName": "安全附件",
    "measureType3": "安全阀",
    "measureText": "反应器按规范要求设置安全阀，安全阀定期校验。",
    "hiddenContent": [
      "1.安全阀是否有泄漏；",
      "2.安全阀进出口管线的阀门是否全开并加铅封；",
      "3.安全阀是否加铅封并悬挂检验标识牌；",
      "4.安全阀是否每年检验一次。"
    ],
    "hiddenContent1": "[\"1.安全阀是否有泄漏；\",\"2.安全阀进出口管线的阀门是否全开并加铅封；\",\"3.安全阀是否加铅封并悬挂检验标识牌；\",\"4.安全阀是否每年检验一次。\"]",
    "positionNo": "-",
    "principalUuid": null,
    "principalName": null,
    "checkCycle": null,
    "cycleUnit": null
  },
  {
    "id": "6f5a5d60-2f46-43f3-9d2b-209830b390e8",
    "measureType1": "3",
    "measureTypeOneName": "人员操作",
    "measureType2": "14",
    "measureTypeTwoName": "交接班",
    "measureType3": "-",
    "measureText": "各班进行严格细致的交接班，并建立记录。",
    "hiddenContent": [
      "各班组是否进行了交接班，是否建立了记录，记录内容是否详实、具体。"
    ],
    "hiddenContent1": "[\"各班组是否进行了交接班，是否建立了记录，记录内容是否详实、具体。\"]",
    "positionNo": "-",
    "principalUuid": null,
    "principalName": null,
    "checkCycle": null,
    "cycleUnit": null
  },
  {
    "id": "729832ed-0ac9-4c68-826e-621310fd2e55",
    "measureType1": "4",
    "measureTypeOneName": "应急措施",
    "measureType2": "15",
    "measureTypeTwoName": "应急设施",
    "measureType3": "-",
    "measureText": "1.有关现场岗位按规范要求设置有应急器材柜，配备空气呼吸器、防化服、带压堵漏等应急器材和烫伤药膏、碘酒、绷带等应急药品，定期检查确保完好并建立检查记录；\n2.设置了应急冲洗设施。",
    "hiddenContent": [
      "1.有关现场岗位是否设置有应急器材柜；（检验检查）",
      "2.配备的应急器材各类和数量是否符合要求；（检验检查）",
      "3.应急器材是否完好，空气呼吸器气瓶压力是否大于等于25MPa：",
      "4.是否定期对应急器材进行检查并建立检查记录。（检验检查）",
      "5.应急冲洗设施是否完好，冲洗水压力是否正常，水质是否正常。"
    ],
    "hiddenContent1": "[\"1.有关现场岗位是否设置有应急器材柜；（检验检查）\",\"2.配备的应急器材各类和数量是否符合要求；（检验检查）\",\"3.应急器材是否完好，空气呼吸器气瓶压力是否大于等于25MPa：\",\"4.是否定期对应急器材进行检查并建立检查记录。（检验检查）\",\"5.应急冲洗设施是否完好，冲洗水压力是否正常，水质是否正常。\"]",
    "positionNo": "-",
    "principalUuid": null,
    "principalName": null,
    "checkCycle": null,
    "cycleUnit": null
  },
  {
    "id": "7fd998bb-5f7f-445a-ba25-b5f7a86aead4",
    "measureType1": "1",
    "measureTypeOneName": "工程技术措施",
    "measureType2": "9",
    "measureTypeTwoName": "其他",
    "measureType3": "防泄漏",
    "measureText": "煤气导淋、排放管、取样口等处设置双阀、盲板、丝堵等防泄漏措施。",
    "hiddenContent": [
      "1.煤气导淋、排放管、取样口等处是否设置双阀、盲板、丝堵等防泄漏措施；",
      "2.管线、法兰及阀门等是否有泄漏现象。"
    ],
    "hiddenContent1": "[\"1.煤气导淋、排放管、取样口等处是否设置双阀、盲板、丝堵等防泄漏措施；\",\"2.管线、法兰及阀门等是否有泄漏现象。\"]",
    "positionNo": "-",
    "principalUuid": null,
    "principalName": null,
    "checkCycle": null,
    "cycleUnit": null
  },
  {
    "id": "807966fa-ddad-4eac-80a5-5083b523daa9",
    "measureType1": "1",
    "measureTypeOneName": "工程技术措施",
    "measureType2": "8",
    "measureTypeTwoName": "安全仪表",
    "measureType3": "气体检测报警",
    "measureText": "一期脱硫单元设置有可燃有毒气体检测报警器。",
    "hiddenContent": [
      "1.界区内可燃有毒气体检测报警器是否完好；",
      "2.可燃有毒气体检测报警器安装高度、覆盖范围是否满足要求；",
      "3.可燃有毒气体检测控制器是否正常；",
      "4.可燃有毒气体检测报警器是否定期检验校定。"
    ],
    "hiddenContent1": "[\"1.界区内可燃有毒气体检测报警器是否完好；\",\"2.可燃有毒气体检测报警器安装高度、覆盖范围是否满足要求；\",\"3.可燃有毒气体检测控制器是否正常；\",\"4.可燃有毒气体检测报警器是否定期检验校定。\"]",
    "positionNo": "-",
    "principalUuid": null,
    "principalName": null,
    "checkCycle": null,
    "cycleUnit": null
  },
  {
    "id": "890f47eb-0316-452c-920a-5073cff6c3c7",
    "measureType1": "1",
    "measureTypeOneName": "工程技术措施",
    "measureType2": "6",
    "measureTypeTwoName": "关键设备",
    "measureType3": "钴钼加氢反应器",
    "measureText": "1.铁钼加氢反应器设置有高高温度SIS联锁，联锁号01TSHH-002/004/007/008/009，联锁指标R101A/B床层温度（01TI002/01TI004)>440℃、R102床层温度（01TI007/01TI008)>435℃、R102出口温度（01TI009）>435℃中有三个及以上符合，系统停车；\n2.钴钼加氢反应器设置高高温度SIS联锁，联锁号01TSHH-021/022/023/026，联锁指标R104床层温度（01TI021/01TI022）>450℃、R104出口温度（011TI023/01TI029）>450℃中有两个及以上符合，系统停车。\n\n\n\n",
    "hiddenContent": [
      "1.一级加氢反应器床层温度是否<440℃，二级加氢反应器床层温度及出口温度是否<435℃；",
      "2.钴钼加氢反应器床层及出口温度是否<450℃。"
    ],
    "hiddenContent1": "[\"1.一级加氢反应器床层温度是否<440℃，二级加氢反应器床层温度及出口温度是否<435℃；\",\"2.钴钼加氢反应器床层及出口温度是否<450℃。\"]",
    "positionNo": "R104A/B",
    "principalUuid": null,
    "principalName": null,
    "checkCycle": null,
    "cycleUnit": null
  }
]

const temp2 = {
  "departmentName": "甲醇分公司（一期）",
  "processName": "一期脱硫转化装置",
  "riskUnitName": "一期焦炉煤气压缩单元",
  "riskLevel": "二级",
  "riskEventName": "一期脱硫单元泄漏造成火灾、爆炸",
  "riskDesc" : "共有3台往复式压缩机：主要涉及H2、CO、CH4等有毒、有害、易燃易爆介质。                                       潜在的主要风险有：                    \n1.如果油泵供油不足，可能会导致轴瓦温度超高，造成轴瓦烧瓦，甚至轴承箱内闪爆现象；\n2.如果气阀及管线螺栓紧固不到位、气体压力上升，导致气阀或法兰等处煤气泄漏，现场人员未穿戴防护用品，可能会导致现场人员煤气中毒，遇点火源可能会造成着火、爆炸。",
  "controlLevel": "部门级",
  "status": "正常"
}

const riskDataTemp2 = [
  {
    "id": "09b30aab-be14-4239-a7e4-cbaf09d3febb",
    "measureType1": "4",
    "measureTypeOneName": "应急措施",
    "measureType2": "18",
    "measureTypeTwoName": "应急预案",
    "measureType3": "-",
    "measureText": "编制有《煤气泄漏着火现场处置方案》，每半年各班组分别对方案演练一次。",
    "hiddenContent": [
      "1.是否编制有《煤气泄漏着火现场处置方案》，是否发放到有关岗位；",
      "2.是否每半年至少组织一次现场处置方案定演练并建立记录。"
    ],
    "hiddenContent1": "[\"1.是否编制有《煤气泄漏着火现场处置方案》，是否发放到有关岗位；\",\"2.是否每半年至少组织一次现场处置方案定演练并建立记录。\"]",
    "positionNo": "-",
    "principalUuid": null,
    "principalName": null,
    "checkCycle": null,
    "cycleUnit": null
  },
  {
    "id": "120b2e37-2967-451f-ac2f-08b33e891d1b",
    "measureType1": "4",
    "measureTypeOneName": "应急措施",
    "measureType2": "17",
    "measureTypeTwoName": "消防设施",
    "measureType3": "-",
    "measureText": "设置有消防水炮、消防栓、干粉灭火器等。",
    "hiddenContent": [
      "1.消防水炮、消防栓、干粉灭火器等是否完好投用；",
      "2.灭火器是否定期检查并建立记录。"
    ],
    "hiddenContent1": "[\"1.消防水炮、消防栓、干粉灭火器等是否完好投用；\",\"2.灭火器是否定期检查并建立记录。\"]",
    "positionNo": "-",
    "principalUuid": null,
    "principalName": null,
    "checkCycle": null,
    "cycleUnit": null
  },
  {
    "id": "2d303c13-1d2a-46d4-9273-0cb4267cf27d",
    "measureType1": "1",
    "measureTypeOneName": "工程技术措施",
    "measureType2": "7",
    "measureTypeTwoName": "安全附件",
    "measureType3": "液位计",
    "measureText": "有关设备设置液位检测。",
    "hiddenContent": [
      "1.液位计是否泄漏，指示是否正常，根部阀是否打开；",
      "2.现场液位计与远传液位计是否一致性。"
    ],
    "hiddenContent1": "[\"1.液位计是否泄漏，指示是否正常，根部阀是否打开；\",\"2.现场液位计与远传液位计是否一致性。\"]",
    "positionNo": "-",
    "principalUuid": null,
    "principalName": null,
    "checkCycle": null,
    "cycleUnit": null
  },
  {
    "id": "3c61585a-3eeb-4220-be88-b02080314650",
    "measureType1": "3",
    "measureTypeOneName": "人员操作",
    "measureType2": "14",
    "measureTypeTwoName": "交接班",
    "measureType3": "-",
    "measureText": "各班进行严格细致的交接班，并建立记录。",
    "hiddenContent": [
      "各班组是否进行了交接班，是否建立了记录，记录内容是否详实、具体。"
    ],
    "hiddenContent1": "[\"各班组是否进行了交接班，是否建立了记录，记录内容是否详实、具体。\"]",
    "positionNo": "-",
    "principalUuid": null,
    "principalName": null,
    "checkCycle": null,
    "cycleUnit": null
  },
  {
    "id": "45d17887-4dcc-4476-a9dc-62ddb1cf911a",
    "measureType1": "1",
    "measureTypeOneName": "工程技术措施",
    "measureType2": "9",
    "measureTypeTwoName": "其他",
    "measureType3": "防泄漏",
    "measureText": "煤气导淋、排放管、取样口等处设置双阀、盲板、丝堵等防泄漏措施。",
    "hiddenContent": [
      "1.煤气导淋、排放管、取样口等处是否设置双阀、盲板、丝堵等防泄漏措施；",
      "2.管线、法兰及阀门等是否有泄漏现象。"
    ],
    "hiddenContent1": "[\"1.煤气导淋、排放管、取样口等处是否设置双阀、盲板、丝堵等防泄漏措施；\",\"2.管线、法兰及阀门等是否有泄漏现象。\"]",
    "positionNo": "-",
    "principalUuid": null,
    "principalName": null,
    "checkCycle": null,
    "cycleUnit": null
  },
  {
    "id": "56928f3c-9d9c-4ba4-82f1-abac5c60635d",
    "measureType1": "1",
    "measureTypeOneName": "工程技术措施",
    "measureType2": "6",
    "measureTypeTwoName": "关键设备",
    "measureType3": "往复式压缩机",
    "measureText": "1.往复式压缩机设置进口压力联锁，PT06301A/B/C 联锁值：-0.1KPa，三级进气压力联锁PT06304A/B/C 联锁值：0.3MPa,三级分离器液位高高联锁：LT06301A/B/C，联锁值：950mm,联锁跳车时系统停车，气柜进气阀关闭；\n2.往复式压缩机设置润滑油压力低低联锁，当油压低至0.25时启动备泵，当触发PS-06308A/B/C、PS-06309A/B/C、PS-06310A/B/C联锁时（三选二）往复机停机。",
    "hiddenContent": [
      "1.往复式压缩机油站现场液位计液位是否在1/2-2/3之间；",
      "2.油站出口压力控制是否在0.35Mpa-0.4Mpa之间；",
      "3.现场活塞杆、气阀等处有无漏煤气现象；",
      "4.机组是否有异音、振动是否异常，填料是否泄漏；",
      "5.注油器各注油点注油是否正常，注油量为25-35滴/分钟。"
    ],
    "hiddenContent1": "[\"1.往复式压缩机油站现场液位计液位是否在1/2-2/3之间；\",\"2.油站出口压力控制是否在0.35Mpa-0.4Mpa之间；\",\"3.现场活塞杆、气阀等处有无漏煤气现象；\",\"4.机组是否有异音、振动是否异常，填料是否泄漏；\",\"5.注油器各注油点注油是否正常，注油量为25-35滴/分钟。\"]",
    "positionNo": "CM6C01A/B/C",
    "principalUuid": null,
    "principalName": null,
    "checkCycle": null,
    "cycleUnit": null
  },
  {
    "id": "573ddeb0-38fb-44a5-ab7b-dd798c8e4d7f",
    "measureType1": "1",
    "measureTypeOneName": "工程技术措施",
    "measureType2": "9",
    "measureTypeTwoName": "其他",
    "measureType3": "视频监控",
    "measureText": "高清视屏监控设置时时监测现场。",
    "hiddenContent": [
      "1.视频是否完好；",
      "2.关键设备是否全覆盖监控。"
    ],
    "hiddenContent1": "[\"1.视频是否完好；\",\"2.关键设备是否全覆盖监控。\"]",
    "positionNo": "-",
    "principalUuid": null,
    "principalName": null,
    "checkCycle": null,
    "cycleUnit": null
  },
  {
    "id": "59408951-46b3-4c60-82b7-e7b10a01d781",
    "measureType1": "2",
    "measureTypeOneName": "维护保养",
    "measureType2": "10",
    "measureTypeTwoName": "动设备",
    "measureType3": "-",
    "measureText": "备用机泵每周进行盘车，定期进行计划性保养。",
    "hiddenContent": [
      "1.备用机泵是否每周进行盘车，是否建立记录；",
      "2.备用机泵润滑油、防护设施等是否正常；",
      "3.运行设备是否有异音、振动是否异常，机封、进出口法兰是否泄漏。"
    ],
    "hiddenContent1": "[\"1.备用机泵是否每周进行盘车，是否建立记录；\",\"2.备用机泵润滑油、防护设施等是否正常；\",\"3.运行设备是否有异音、振动是否异常，机封、进出口法兰是否泄漏。\"]",
    "positionNo": "-",
    "principalUuid": null,
    "principalName": null,
    "checkCycle": null,
    "cycleUnit": null
  },
  {
    "id": "60bd5787-ac01-4e1d-84b6-f791d6c64346",
    "measureType1": "1",
    "measureTypeOneName": "工程技术措施",
    "measureType2": "9",
    "measureTypeTwoName": "其他",
    "measureType3": "防火防爆防静电",
    "measureText": "1.装置楼梯入口处设置静电释放器；\n2.设备本体设置静电接地；\n3.四条螺栓的煤气管线法兰设置静电跨接；\n4.防爆区使用防爆等级符合要求的防爆电气设备。",
    "hiddenContent": [
      "1.装置楼梯入口处是否设置静电释放器，静电释放器是否完好；",
      "2.设备本体是否设置静电接地，接地数量是否符合要求，接地是否完好；",
      "3.四条螺栓的煤气管线法兰是否设置静电跨接，跨接线是否完好；",
      "4.防爆区电气设备防爆等级是否符合要求，设备设施防爆密封是否完好。"
    ],
    "hiddenContent1": "[\"1.装置楼梯入口处是否设置静电释放器，静电释放器是否完好；\",\"2.设备本体是否设置静电接地，接地数量是否符合要求，接地是否完好；\",\"3.四条螺栓的煤气管线法兰是否设置静电跨接，跨接线是否完好；\",\"4.防爆区电气设备防爆等级是否符合要求，设备设施防爆密封是否完好。\"]",
    "positionNo": "-",
    "principalUuid": null,
    "principalName": null,
    "checkCycle": null,
    "cycleUnit": null
  },
  {
    "id": "67e8bd31-2f35-42a8-97c6-400b08a86f9e",
    "measureType1": "3",
    "measureTypeOneName": "人员操作",
    "measureType2": "12",
    "measureTypeTwoName": "人员资质",
    "measureType3": "-",
    "measureText": "操作人员经培训取得上岗证；特种作业人员和特种设备操作人员经培训取得相应资质证书。",
    "hiddenContent": [
      "1.操作人员是否经培训取得上岗证；",
      "2.特种作业人员和特种设备操作人员是否经培训取得相应资质证书。"
    ],
    "hiddenContent1": "[\"1.操作人员是否经培训取得上岗证；\",\"2.特种作业人员和特种设备操作人员是否经培训取得相应资质证书。\"]",
    "positionNo": "-",
    "principalUuid": null,
    "principalName": null,
    "checkCycle": null,
    "cycleUnit": null
  },
  {
    "id": "6b6a85c2-ac62-45ca-9d15-e3d46455e869",
    "measureType1": "1",
    "measureTypeOneName": "工程技术措施",
    "measureType2": "8",
    "measureTypeTwoName": "安全仪表",
    "measureType3": "联锁",
    "measureText": "DCS联锁正常投用，联锁摘除严格办理审批手续，联锁定期测试。",
    "hiddenContent": [
      "1.联锁是否正常投用；",
      "2.联锁摘除是否办理审批手续；",
      "3.联锁是否定期测试，是否建立相关记录。"
    ],
    "hiddenContent1": "[\"1.联锁是否正常投用；\",\"2.联锁摘除是否办理审批手续；\",\"3.联锁是否定期测试，是否建立相关记录。\"]",
    "positionNo": "-",
    "principalUuid": null,
    "principalName": null,
    "checkCycle": null,
    "cycleUnit": null
  },
  {
    "id": "72712141-c985-4b33-b835-a4270c0dfccc",
    "measureType1": "1",
    "measureTypeOneName": "工程技术措施",
    "measureType2": "8",
    "measureTypeTwoName": "安全仪表",
    "measureType3": "气体检测报警",
    "measureText": "一期焦炉煤气压缩单元设置可燃有毒气体报警器。",
    "hiddenContent": [
      "1.机组周围可燃有毒气体检测报警器是否完好；",
      "2.可燃有毒气体检测报警器安装高度、覆盖范围是否满足要求；",
      "3.可燃有毒气体检测控制器是否正常；",
      "4.可燃有毒气体检测报警器是否定期检验校定。"
    ],
    "hiddenContent1": "[\"1.机组周围可燃有毒气体检测报警器是否完好；\",\"2.可燃有毒气体检测报警器安装高度、覆盖范围是否满足要求；\",\"3.可燃有毒气体检测控制器是否正常；\",\"4.可燃有毒气体检测报警器是否定期检验校定。\"]",
    "positionNo": "-",
    "principalUuid": null,
    "principalName": null,
    "checkCycle": null,
    "cycleUnit": null
  },
  {
    "id": "84002be1-68c0-43b2-bce5-779aa4205ff7",
    "measureType1": "2",
    "measureTypeOneName": "维护保养",
    "measureType2": "11",
    "measureTypeTwoName": "静设备",
    "measureType3": "-",
    "measureText": "对静设备定期进行设备检查检测。",
    "hiddenContent": [
      "静设备是否定期检查、检测，是否建立记录。"
    ],
    "hiddenContent1": "[\"静设备是否定期检查、检测，是否建立记录。\"]",
    "positionNo": "-",
    "principalUuid": null,
    "principalName": null,
    "checkCycle": null,
    "cycleUnit": null
  },
  {
    "id": "947d05ae-d402-4156-bfb3-970668c46230",
    "measureType1": "3",
    "measureTypeOneName": "人员操作",
    "measureType2": "13",
    "measureTypeTwoName": "操作记录",
    "measureType3": "-",
    "measureText": "中控人员随时对DCS画面进行查看、巡检人员定时进行现场巡检，并分别建立记录。",
    "hiddenContent": [
      "1.中控人员是否对DCS报警及其他异常情况及时进行处理，是否建立操作记录；",
      "2.巡检人员是否定时进行现场巡检，是否建立记录。"
    ],
    "hiddenContent1": "[\"1.中控人员是否对DCS报警及其他异常情况及时进行处理，是否建立操作记录；\",\"2.巡检人员是否定时进行现场巡检，是否建立记录。\"]",
    "positionNo": "-",
    "principalUuid": null,
    "principalName": null,
    "checkCycle": null,
    "cycleUnit": null
  },
  {
    "id": "9a0d472e-d363-450f-a381-32fac7007ffd",
    "measureType1": "1",
    "measureTypeOneName": "工程技术措施",
    "measureType2": "7",
    "measureTypeTwoName": "安全附件",
    "measureType3": "压力表",
    "measureText": "设置压力检测，AB类压力表定期校验。",
    "hiddenContent": [
      "1.压力表根部阀应打开，压力在正常工作范围内，无泄漏无振动，无故障；",
      "2.压力表是否有检验标识，是否在有效期内；",
      "3.AB类压力表是否定期校验。"
    ],
    "hiddenContent1": "[\"1.压力表根部阀应打开，压力在正常工作范围内，无泄漏无振动，无故障；\",\"2.压力表是否有检验标识，是否在有效期内；\",\"3.AB类压力表是否定期校验。\"]",
    "positionNo": "-",
    "principalUuid": null,
    "principalName": null,
    "checkCycle": null,
    "cycleUnit": null
  },
  {
    "id": "cc7e7b49-1825-4ad4-8d38-e5fe2f739f39",
    "measureType1": "4",
    "measureTypeOneName": "应急措施",
    "measureType2": "16",
    "measureTypeTwoName": "个体防护",
    "measureType3": "-",
    "measureText": "按岗位风险，配备过滤式防毒面具、防护面罩、耳塞等防护用品。",
    "hiddenContent": [
      "1.岗位人员进入现场是否正确佩戴安全防护用品；",
      "2.个人安全防护用品是否完好、在有效期内。"
    ],
    "hiddenContent1": "[\"1.岗位人员进入现场是否正确佩戴安全防护用品；\",\"2.个人安全防护用品是否完好、在有效期内。\"]",
    "positionNo": "-",
    "principalUuid": null,
    "principalName": null,
    "checkCycle": null,
    "cycleUnit": null
  },
  {
    "id": "dafd1707-bf8a-4080-b770-864950aab734",
    "measureType1": "4",
    "measureTypeOneName": "应急措施",
    "measureType2": "15",
    "measureTypeTwoName": "应急设施",
    "measureType3": "-",
    "measureText": "1.有关现场岗位按规范要求设置有应急器材柜，配备空气呼吸器、防化服、带压堵漏等应急器材和烫伤药膏、碘酒、绷带等应急药品，定期检查确保完好并建立检查记录；\n2.设置了应急冲洗设施。",
    "hiddenContent": [
      "1.有关现场岗位是否设置有应急器材柜；（检验检查）",
      "2.配备的应急器材各类和数量是否符合要求；（检验检查）",
      "3.应急器材是否完好，空气呼吸器气瓶压力是否大于等于25MPa：",
      "4.是否定期对应急器材进行检查并建立检查记录。（检验检查）",
      "5.应急冲洗设施是否完好，冲洗水压力是否正常，水质是否正常。"
    ],
    "hiddenContent1": "[\"1.有关现场岗位是否设置有应急器材柜；（检验检查）\",\"2.配备的应急器材各类和数量是否符合要求；（检验检查）\",\"3.应急器材是否完好，空气呼吸器气瓶压力是否大于等于25MPa：\",\"4.是否定期对应急器材进行检查并建立检查记录。（检验检查）\",\"5.应急冲洗设施是否完好，冲洗水压力是否正常，水质是否正常。\"]",
    "positionNo": "-",
    "principalUuid": null,
    "principalName": null,
    "checkCycle": null,
    "cycleUnit": null
  },
  {
    "id": "dc02bf1e-d3ff-4e8b-9a2e-83cdfe6f4ff2",
    "measureType1": "1",
    "measureTypeOneName": "工程技术措施",
    "measureType2": "9",
    "measureTypeTwoName": "其他",
    "measureType3": "防触电",
    "measureText": "电气设备设置保护接地。",
    "hiddenContent": [
      "电气设备设置保护接地是否串接，接地是否牢固。"
    ],
    "hiddenContent1": "[\"电气设备设置保护接地是否串接，接地是否牢固。\"]",
    "positionNo": "-",
    "principalUuid": null,
    "principalName": null,
    "checkCycle": null,
    "cycleUnit": null
  },
  {
    "id": "e3886e55-6c88-4ca5-82c5-35024b5c5e59",
    "measureType1": "1",
    "measureTypeOneName": "工程技术措施",
    "measureType2": "7",
    "measureTypeTwoName": "安全附件",
    "measureType3": "安全阀",
    "measureText": "有关设备按规范要求设置安全阀，安全阀定期校验。",
    "hiddenContent": [
      "1.安全阀是否有泄漏；",
      "2.安全阀进出口管线的阀门是否全开并加铅封；",
      "3.安全阀是否加铅封并悬挂检验标识牌；",
      "4.安全阀是否每年检验一次。"
    ],
    "hiddenContent1": "[\"1.安全阀是否有泄漏；\",\"2.安全阀进出口管线的阀门是否全开并加铅封；\",\"3.安全阀是否加铅封并悬挂检验标识牌；\",\"4.安全阀是否每年检验一次。\"]",
    "positionNo": "-",
    "principalUuid": null,
    "principalName": null,
    "checkCycle": null,
    "cycleUnit": null
  },
  {
    "id": "e3c51279-8895-47b5-bf33-33fcbc317cdd",
    "measureType1": "1",
    "measureTypeOneName": "工程技术措施",
    "measureType2": "7",
    "measureTypeTwoName": "安全附件",
    "measureType3": "温度计",
    "measureText": "有关设备设置温度检测。",
    "hiddenContent": [
      "1.温度表是否完好；",
      "2.温度表是否每年进行校验。"
    ],
    "hiddenContent1": "[\"1.温度表是否完好；\",\"2.温度表是否每年进行校验。\"]",
    "positionNo": "-",
    "principalUuid": null,
    "principalName": null,
    "checkCycle": null,
    "cycleUnit": null
  },
  {
    "id": "fca0a108-8981-4fe1-93a9-141b26bd5032",
    "measureType1": "1",
    "measureTypeOneName": "工程技术措施",
    "measureType2": "6",
    "measureTypeTwoName": "关键设备",
    "measureType3": "油站泵",
    "measureText": "1.油泵出口压力设置压力低低自启系统，润滑油压力≤0.25Mpa时,备用油泵自启；\n2.泵出口设置压力表监测泵正常运行；\n3.机泵装备远程振动监控系统。",
    "hiddenContent": [
      "1.油泵出口压力是否在0.35～0.4MPa范围内；",
      "2.泵油杯液位是否在1/2-2/3范围内；",
      "3.泵出口过滤器差压＜0.1Mpa；",
      "4.泵是否有异音、振动是否异常，机封、进出口法兰是否泄漏。"
    ],
    "hiddenContent1": "[\"1.油泵出口压力是否在0.35～0.4MPa范围内；\",\"2.泵油杯液位是否在1/2-2/3范围内；\",\"3.泵出口过滤器差压＜0.1Mpa；\",\"4.泵是否有异音、振动是否异常，机封、进出口法兰是否泄漏。\"]",
    "positionNo": "-",
    "principalUuid": null,
    "principalName": null,
    "checkCycle": null,
    "cycleUnit": null
  }
]

const temp3 = {
  "departmentName": "甲醇分公司（一期）",
  "processName": "一期脱硫转化装置",
  "riskUnitName": "一期转化单元",
  "riskLevel": "二级",
  "riskEventName": "一期脱硫单元泄漏造成火灾、爆炸",
  "riskDesc" : "主要涉及H2、CO、CH4等有毒、有害、易燃易爆介质。                                       潜在的主要风险有：                    \n1.如果氧气流量瞬间过多会导致转化炉出口温度大幅度上升，超出设备承受极限，造成煤气泄漏或者爆炸；\n2.如果汽包液位过低补水不及时导致汽包干锅，突然补水造成汽包爆炸；\n3.如果煤气管线、法兰、阀门等位置因冲刷、老化、腐蚀等原因出现漏点，导致煤气泄漏，现场人员未穿戴防护用品，可能会导致现场人员煤气中毒，遇点火源可能造成火灾、爆炸；\n4.如果加热炉炉膛内气体置换不合格，造成可燃气体聚集，点火时导致炉膛内闪爆；\n5.如果燃料气压力过低，导致加热炉炉膛内火焰回火，造成闪爆。\n",
  "controlLevel": "部门级",
  "status": "正常"
}

const riskDataTemp3 = [
  {
    "id": "0a0b2355-ad62-4590-a8ff-5b8bef6f31e9",
    "measureType1": "1",
    "measureTypeOneName": "工程技术措施",
    "measureType2": "6",
    "measureTypeTwoName": "关键设备",
    "measureType3": "加热炉",
    "measureText": "1.加热炉设置燃料气压力低低联锁：02PSLL06/07,当压力低于0.02MPa时，系统SIS停车；\n2.加热炉设置燃烧空气压力低低联锁：02PSLL034/035,当压力低于0.51KPa时，系统SIS停车；\n3.加热炉设置炉膛负压高高联锁：02PSHH025/026,当负压高于-40Pa时，系统SIS停车；\n4.加热炉设置火焰检测熄火保护SIS联锁。",
    "hiddenContent": [
      "1.炉膛内火焰呈短、齐状，火焰为蓝色，焰心桔红，火焰无飞火现象；",
      "2.炉膛内氧气含量是否在6%--12%之间；",
      "3.现场烧嘴是否有回火现象；",
      "4.现场是否有煤气泄漏、报警现象。"
    ],
    "hiddenContent1": "[\"1.炉膛内火焰呈短、齐状，火焰为蓝色，焰心桔红，火焰无飞火现象；\",\"2.炉膛内氧气含量是否在6%--12%之间；\",\"3.现场烧嘴是否有回火现象；\",\"4.现场是否有煤气泄漏、报警现象。\"]",
    "positionNo": "F-201",
    "principalUuid": null,
    "principalName": null,
    "checkCycle": null,
    "cycleUnit": null
  },
  {
    "id": "1a0b6a05-68db-4880-89d0-a06f69702de7",
    "measureType1": "1",
    "measureTypeOneName": "工程技术措施",
    "measureType2": "6",
    "measureTypeTwoName": "关键设备",
    "measureType3": "转化炉",
    "measureText": "1.转化炉设置出口温度SIS联锁，温度联锁号02TSHH-C206A/B/C，联锁值：1050℃（三选二），当高于联锁值时转化系统SIS联锁停车，切断煤气进气阀门02FICA042、氧气进气阀门02FV208等阀门；\n2.转化炉夹套设置液位低低SIS联锁，当02LSLL-201、02LSLL-202液位低于400mm时，转化系统SIS联锁停车，切断煤气进气阀门02FICA042、氧气进气阀门02FV208等阀门；\n3.转化炉设置氧焦比、水碳比SIS联锁，水碳比＜1.1，氧焦比＞0.256，系统SIS联锁停车，切断煤气进气阀门02FICA042、氧气进气阀门02FV208等阀门。",
    "hiddenContent": [
      "1.转化炉夹套现场放散观察口冒气正常，夹套溢流管线正常溢流；",
      "2.仪表空气总管压力是否>0.4MPa，自调阀处仪表气无漏气现象；",
      "3.转化汽包现场液位计液位是否在1/2-2/3之间。"
    ],
    "hiddenContent1": "[\"1.转化炉夹套现场放散观察口冒气正常，夹套溢流管线正常溢流；\",\"2.仪表空气总管压力是否>0.4MPa，自调阀处仪表气无漏气现象；\",\"3.转化汽包现场液位计液位是否在1/2-2/3之间。\"]",
    "positionNo": "F-202",
    "principalUuid": null,
    "principalName": null,
    "checkCycle": null,
    "cycleUnit": null
  },
  {
    "id": "1cdcb54a-3343-4c5b-a78a-1d994c1ab639",
    "measureType1": "1",
    "measureTypeOneName": "工程技术措施",
    "measureType2": "9",
    "measureTypeTwoName": "其他",
    "measureType3": "防泄漏",
    "measureText": "煤气导淋、排放管、取样口等处设置双阀、盲板、丝堵等防泄漏措施。",
    "hiddenContent": [
      "1.煤气导淋、排放管、取样口等处是否设置双阀、盲板、丝堵等防泄漏措施；",
      "2.管线、法兰及阀门等是否有泄漏现象。"
    ],
    "hiddenContent1": "[\"1.煤气导淋、排放管、取样口等处是否设置双阀、盲板、丝堵等防泄漏措施；\",\"2.管线、法兰及阀门等是否有泄漏现象。\"]",
    "positionNo": "-",
    "principalUuid": null,
    "principalName": null,
    "checkCycle": null,
    "cycleUnit": null
  },
  {
    "id": "216e0164-0fb7-463a-bfba-14da5d67fbab",
    "measureType1": "1",
    "measureTypeOneName": "工程技术措施",
    "measureType2": "7",
    "measureTypeTwoName": "安全附件",
    "measureType3": "压力表",
    "measureText": "设置压力检测，AB类压力表定期校验。",
    "hiddenContent": [
      "1.压力表根部阀应打开，压力在正常工作范围内，无泄漏无振动，无故障；",
      "2.压力表是否有检验标识，是否在有效期内；",
      "3.AB类压力表是否定期校验。"
    ],
    "hiddenContent1": "[\"1.压力表根部阀应打开，压力在正常工作范围内，无泄漏无振动，无故障；\",\"2.压力表是否有检验标识，是否在有效期内；\",\"3.AB类压力表是否定期校验。\"]",
    "positionNo": "-",
    "principalUuid": null,
    "principalName": null,
    "checkCycle": null,
    "cycleUnit": null
  },
  {
    "id": "31fbe0ed-5046-450d-a70e-7bc5616ef41c",
    "measureType1": "2",
    "measureTypeOneName": "维护保养",
    "measureType2": "11",
    "measureTypeTwoName": "静设备",
    "measureType3": "-",
    "measureText": "对静设备定期进行设备检查检测。",
    "hiddenContent": [
      "静设备是否定期检查、检测，是否建立记录。"
    ],
    "hiddenContent1": "[\"静设备是否定期检查、检测，是否建立记录。\"]",
    "positionNo": "-",
    "principalUuid": null,
    "principalName": null,
    "checkCycle": null,
    "cycleUnit": null
  },
  {
    "id": "32af89ce-5c02-4a51-a74b-541534567087",
    "measureType1": "1",
    "measureTypeOneName": "工程技术措施",
    "measureType2": "9",
    "measureTypeTwoName": "其他",
    "measureType3": "防触电",
    "measureText": "电气设备设置保护接地。",
    "hiddenContent": [
      "电气设备设置保护接地是否串接，接地是否牢固。"
    ],
    "hiddenContent1": "[\"电气设备设置保护接地是否串接，接地是否牢固。\"]",
    "positionNo": "-",
    "principalUuid": null,
    "principalName": null,
    "checkCycle": null,
    "cycleUnit": null
  },
  {
    "id": "35fda82f-7038-41eb-a45e-d3b891a685b8",
    "measureType1": "1",
    "measureTypeOneName": "工程技术措施",
    "measureType2": "7",
    "measureTypeTwoName": "安全附件",
    "measureType3": "液位计",
    "measureText": "有关设备设置液位检测。",
    "hiddenContent": [
      "1.液位计是否泄漏，指示是否正常，根部阀是否打开；",
      "2.现场液位计与远传液位计是否一致性。"
    ],
    "hiddenContent1": "[\"1.液位计是否泄漏，指示是否正常，根部阀是否打开；\",\"2.现场液位计与远传液位计是否一致性。\"]",
    "positionNo": "-",
    "principalUuid": null,
    "principalName": null,
    "checkCycle": null,
    "cycleUnit": null
  },
  {
    "id": "5027b235-4866-4114-b3b4-551279e2672d",
    "measureType1": "1",
    "measureTypeOneName": "工程技术措施",
    "measureType2": "8",
    "measureTypeTwoName": "安全仪表",
    "measureType3": "联锁",
    "measureText": "SIS联锁正常投用，联锁摘除严格办理审批手续，联锁定期测试。",
    "hiddenContent": [
      "1.联锁是否正常投用；",
      "2.联锁摘除是否办理审批手续；",
      "3.联锁是否定期测试，是否建立相关记录。"
    ],
    "hiddenContent1": "[\"1.联锁是否正常投用；\",\"2.联锁摘除是否办理审批手续；\",\"3.联锁是否定期测试，是否建立相关记录。\"]",
    "positionNo": "-",
    "principalUuid": null,
    "principalName": null,
    "checkCycle": null,
    "cycleUnit": null
  },
  {
    "id": "655d9e57-72fa-4bf4-8d78-804ef0890b2b",
    "measureType1": "4",
    "measureTypeOneName": "应急措施",
    "measureType2": "17",
    "measureTypeTwoName": "消防设施",
    "measureType3": "-",
    "measureText": "设置有消防栓、干粉灭火器等。",
    "hiddenContent": [
      "1.消防水炮、消防栓、干粉灭火器等是否完好投用；",
      "2.灭火器是否定期检查并建立记录。"
    ],
    "hiddenContent1": "[\"1.消防水炮、消防栓、干粉灭火器等是否完好投用；\",\"2.灭火器是否定期检查并建立记录。\"]",
    "positionNo": "-",
    "principalUuid": null,
    "principalName": null,
    "checkCycle": null,
    "cycleUnit": null
  },
  {
    "id": "68889d5b-a902-4446-a342-260a4144f8fd",
    "measureType1": "4",
    "measureTypeOneName": "应急措施",
    "measureType2": "18",
    "measureTypeTwoName": "应急预案",
    "measureType3": "-",
    "measureText": "编制有《煤气泄漏着火现场处置方案》，每半年各班组分别对方案演练一次。",
    "hiddenContent": [
      "1.是否编制有《煤气泄漏着火现场处置方案》，是否发放到有关岗位；",
      "2.是否每半年至少组织一次现场处置方案定演练并建立记录。"
    ],
    "hiddenContent1": "[\"1.是否编制有《煤气泄漏着火现场处置方案》，是否发放到有关岗位；\",\"2.是否每半年至少组织一次现场处置方案定演练并建立记录。\"]",
    "positionNo": "-",
    "principalUuid": null,
    "principalName": null,
    "checkCycle": null,
    "cycleUnit": null
  },
  {
    "id": "6fdc3cbe-2d83-49b7-bd92-ed75580c01b3",
    "measureType1": "1",
    "measureTypeOneName": "工程技术措施",
    "measureType2": "7",
    "measureTypeTwoName": "安全附件",
    "measureType3": "温度计",
    "measureText": "有关设备设置温度检测。",
    "hiddenContent": [
      "1.温度表是否完好；",
      "2.温度表是否每年进行校验。"
    ],
    "hiddenContent1": "[\"1.温度表是否完好；\",\"2.温度表是否每年进行校验。\"]",
    "positionNo": "-",
    "principalUuid": null,
    "principalName": null,
    "checkCycle": null,
    "cycleUnit": null
  },
  {
    "id": "9b527e77-4525-4cbe-9078-7978f168f768",
    "measureType1": "4",
    "measureTypeOneName": "应急措施",
    "measureType2": "15",
    "measureTypeTwoName": "应急设施",
    "measureType3": "-",
    "measureText": "1.有关现场岗位按规范要求设置有应急器材柜，配备空气呼吸器、防化服、带压堵漏等应急器材和烫伤药膏、碘酒、绷带等应急药品，定期检查确保完好并建立检查记录；\n2.设置了应急冲洗设施。",
    "hiddenContent": [
      "1.有关现场岗位是否设置有应急器材柜；（检验检查）",
      "2.配备的应急器材各类和数量是否符合要求；（检验检查）",
      "3.应急器材是否完好，空气呼吸器气瓶压力是否大于等于25MPa：",
      "4.是否定期对应急器材进行检查并建立检查记录。（检验检查）",
      "5.应急冲洗设施是否完好，冲洗水压力是否正常，水质是否正常。"
    ],
    "hiddenContent1": "[\"1.有关现场岗位是否设置有应急器材柜；（检验检查）\",\"2.配备的应急器材各类和数量是否符合要求；（检验检查）\",\"3.应急器材是否完好，空气呼吸器气瓶压力是否大于等于25MPa：\",\"4.是否定期对应急器材进行检查并建立检查记录。（检验检查）\",\"5.应急冲洗设施是否完好，冲洗水压力是否正常，水质是否正常。\"]",
    "positionNo": "-",
    "principalUuid": null,
    "principalName": null,
    "checkCycle": null,
    "cycleUnit": null
  },
  {
    "id": "a94fcf52-80f4-4be7-a0cc-a18c3267eaaf",
    "measureType1": "1",
    "measureTypeOneName": "工程技术措施",
    "measureType2": "7",
    "measureTypeTwoName": "安全附件",
    "measureType3": "安全阀",
    "measureText": "有关设备按规范要求设置安全阀，安全阀定期校验。",
    "hiddenContent": [
      "1.安全阀是否有泄漏；",
      "2.安全阀进出口管线的阀门是否全开并加铅封；",
      "3.安全阀是否加铅封并悬挂检验标识牌；",
      "4.安全阀是否每年检验一次。"
    ],
    "hiddenContent1": "[\"1.安全阀是否有泄漏；\",\"2.安全阀进出口管线的阀门是否全开并加铅封；\",\"3.安全阀是否加铅封并悬挂检验标识牌；\",\"4.安全阀是否每年检验一次。\"]",
    "positionNo": "-",
    "principalUuid": null,
    "principalName": null,
    "checkCycle": null,
    "cycleUnit": null
  },
  {
    "id": "bd187936-5124-4284-ae79-04884f373a8e",
    "measureType1": "3",
    "measureTypeOneName": "人员操作",
    "measureType2": "14",
    "measureTypeTwoName": "交接班",
    "measureType3": "-",
    "measureText": "各班进行严格细致的交接班，并建立记录。",
    "hiddenContent": [
      "各班组是否进行了交接班，是否建立了记录，记录内容是否详实、具体。"
    ],
    "hiddenContent1": "[\"各班组是否进行了交接班，是否建立了记录，记录内容是否详实、具体。\"]",
    "positionNo": "-",
    "principalUuid": null,
    "principalName": null,
    "checkCycle": null,
    "cycleUnit": null
  },
  {
    "id": "c246754e-8024-4e0b-870f-0a4ec6bdda23",
    "measureType1": "1",
    "measureTypeOneName": "工程技术措施",
    "measureType2": "9",
    "measureTypeTwoName": "其他",
    "measureType3": "视频监控",
    "measureText": "高清视屏监控设施时时监测现场。",
    "hiddenContent": [
      "1.视频是否完好；",
      "2.关键设备是否全覆盖监控。"
    ],
    "hiddenContent1": "[\"1.视频是否完好；\",\"2.关键设备是否全覆盖监控。\"]",
    "positionNo": "-",
    "principalUuid": null,
    "principalName": null,
    "checkCycle": null,
    "cycleUnit": null
  },
  {
    "id": "d5968a6d-b079-407c-9bf8-e88ad1b5d535",
    "measureType1": "2",
    "measureTypeOneName": "维护保养",
    "measureType2": "10",
    "measureTypeTwoName": "动设备",
    "measureType3": "-",
    "measureText": "备用机泵每周进行盘车，定期进行计划性保养。",
    "hiddenContent": [
      "1.备用机泵是否每周进行盘车，是否建立记录；",
      "2.备用机泵润滑油、防护设施等是否正常；",
      "3.运行设备是否有异音、振动是否异常，机封、进出口法兰是否泄漏。"
    ],
    "hiddenContent1": "[\"1.备用机泵是否每周进行盘车，是否建立记录；\",\"2.备用机泵润滑油、防护设施等是否正常；\",\"3.运行设备是否有异音、振动是否异常，机封、进出口法兰是否泄漏。\"]",
    "positionNo": "-",
    "principalUuid": null,
    "principalName": null,
    "checkCycle": null,
    "cycleUnit": null
  },
  {
    "id": "dcb0e539-a5d3-4e8e-a81b-c3ff3dca58e8",
    "measureType1": "4",
    "measureTypeOneName": "应急措施",
    "measureType2": "16",
    "measureTypeTwoName": "个体防护",
    "measureType3": "-",
    "measureText": "按岗位风险，配备过滤式防毒面具、防护面罩、耳塞等防护用品。",
    "hiddenContent": [
      "1.岗位人员进入现场是否正确佩戴安全防护用品；",
      "2.个人安全防护用品是否完好、在有效期内。"
    ],
    "hiddenContent1": "[\"1.岗位人员进入现场是否正确佩戴安全防护用品；\",\"2.个人安全防护用品是否完好、在有效期内。\"]",
    "positionNo": "-",
    "principalUuid": null,
    "principalName": null,
    "checkCycle": null,
    "cycleUnit": null
  },
  {
    "id": "e2a6f565-72c2-4ee1-a11a-06d1eb36711e",
    "measureType1": "3",
    "measureTypeOneName": "人员操作",
    "measureType2": "13",
    "measureTypeTwoName": "操作记录",
    "measureType3": "-",
    "measureText": "中控人员随时对DCS画面进行查看、巡检人员定时进行现场巡检，并分别建立记录。",
    "hiddenContent": [
      "1.中控人员是否对DCS报警及其他异常情况及时进行处理，是否建立操作记录；",
      "2.巡检人员是否定时进行现场巡检，是否建立记录。"
    ],
    "hiddenContent1": "[\"1.中控人员是否对DCS报警及其他异常情况及时进行处理，是否建立操作记录；\",\"2.巡检人员是否定时进行现场巡检，是否建立记录。\"]",
    "positionNo": "-",
    "principalUuid": null,
    "principalName": null,
    "checkCycle": null,
    "cycleUnit": null
  },
  {
    "id": "e785e6ff-f159-4029-9c15-b211d8a2dd1c",
    "measureType1": "1",
    "measureTypeOneName": "工程技术措施",
    "measureType2": "8",
    "measureTypeTwoName": "安全仪表",
    "measureType3": "气体检测报警",
    "measureText": "一期转化单元设置可燃有毒气体报警器。",
    "hiddenContent": [
      "1.装置周围可燃有毒气体检测报警器是否完好；",
      "2.可燃有毒气体检测报警器安装高度、覆盖范围是否满足要求；",
      "3.可燃有毒气体检测控制器是否正常；",
      "4.可燃有毒气体检测报警器是否定期检验校定。"
    ],
    "hiddenContent1": "[\"1.装置周围可燃有毒气体检测报警器是否完好；\",\"2.可燃有毒气体检测报警器安装高度、覆盖范围是否满足要求；\",\"3.可燃有毒气体检测控制器是否正常；\",\"4.可燃有毒气体检测报警器是否定期检验校定。\"]",
    "positionNo": "-",
    "principalUuid": null,
    "principalName": null,
    "checkCycle": null,
    "cycleUnit": null
  },
  {
    "id": "f7dde608-51de-45ea-a533-1168cbd247db",
    "measureType1": "1",
    "measureTypeOneName": "工程技术措施",
    "measureType2": "9",
    "measureTypeTwoName": "其他",
    "measureType3": "防火防爆防静电",
    "measureText": "1.设备本体设置静电接地；\n2.反应槽底部等处设置防火涂层；\n3.四条螺栓的煤气管线法兰设置静电跨接；\n4.防爆区使用防爆等级符合要求的防爆电气设备。",
    "hiddenContent": [
      "1.设备底部等处防火涂层是否完好；",
      "2.设备本体是否设置静电接地，接地数量是否符合要求，接地是否完好；",
      "3.四条螺栓的煤气管线法兰是否设置静电跨接，跨接线是否完好；",
      "4.防爆区电气设备防爆等级是否符合要求，设备设施防爆密封是否完好。"
    ],
    "hiddenContent1": "[\"1.设备底部等处防火涂层是否完好；\",\"2.设备本体是否设置静电接地，接地数量是否符合要求，接地是否完好；\",\"3.四条螺栓的煤气管线法兰是否设置静电跨接，跨接线是否完好；\",\"4.防爆区电气设备防爆等级是否符合要求，设备设施防爆密封是否完好。\"]",
    "positionNo": "-",
    "principalUuid": null,
    "principalName": null,
    "checkCycle": null,
    "cycleUnit": null
  },
  {
    "id": "fb679203-68ee-4bfa-a0b2-4f5542b4bde7",
    "measureType1": "3",
    "measureTypeOneName": "人员操作",
    "measureType2": "12",
    "measureTypeTwoName": "人员资质",
    "measureType3": "-",
    "measureText": "操作人员经培训取得上岗证；特种作业人员和特种设备操作人员经培训取得相应资质证书。",
    "hiddenContent": [
      "1.操作人员是否经培训取得上岗证；",
      "2.特种作业人员和特种设备操作人员是否经培训取得相应资质证书。"
    ],
    "hiddenContent1": "[\"1.操作人员是否经培训取得上岗证；\",\"2.特种作业人员和特种设备操作人员是否经培训取得相应资质证书。\"]",
    "positionNo": "-",
    "principalUuid": null,
    "principalName": null,
    "checkCycle": null,
    "cycleUnit": null
  }
]

const riskData1 = riskDataTemp1.map(item => Object.assign(item, temp1))
const riskData2 = riskDataTemp2.map(item => Object.assign(item, temp2))
const riskData3 = riskDataTemp3.map(item => Object.assign(item, temp3))

let tempRiskData = [...riskData1]
const nameArr = ['departmentName', 'processName', 'riskUnitName', 'riskEventName', 'controlLevel']
nameArr.forEach(item => {
  tempRiskData = mergeColumns(tempRiskData, item)
})

export const riskData = tempRiskData