// 重大危险源
export const dangerSourcesData = [
  {
    id: 'TE-109B-1',
    data: '-999.00',
    unit: '℃',
    low1: null,
    low2: null,
    high1: '45.00',
    high2: '55.00',
    category: '温度',
    address: '氢化反应釜温度传感器',
    status: 1
  },
  {
    id: 'TE-109C-1',
    data: '-999.00',
    unit: '℃',
    low1: null,
    low2: null,
    high1: '45.00',
    high2: '55.00',
    category: '温度',
    address: '氢化反应釜温度传感器',
    status: 2
  },
  {
    id: 'TE-109A-1',
    data: '-999.00',
    unit: '℃',
    low1: null,
    low2: null,
    high1: '45.00',
    high2: '55.00',
    category: '温度',
    address: '氢化反应釜温度传感器',
    status: 0
  },
  {
    id: 'TE-109B-4',
    data: '4.65',
    unit: '℃',
    low1: null,
    low2: null,
    high1: '45.00',
    high2: '55.00',
    category: '温度',
    address: '氢化反应釜温度传感器',
    status: 0
  },
  {
    id: 'TE-109B-5',
    data: '53.19',
    unit: 'Kpa',
    low1: null,
    low2: null,
    high1: '90.00',
    high2: '100.00',
    category: '压力',
    address: '氢化反应釜压力传感器',
    status: 0
  },
  {
    id: 'TE-109B-6',
    data: '115.26',
    unit: 'Kpa',
    low1: null,
    low2: null,
    high1: '280.00',
    high2: '300.00',
    category: '压力',
    address: '冷却介质压力传感器',
    status: 0
  },
  {
    id: 'TE-109B-7',
    data: '-999.00',
    unit: '℃',
    low1: '35.00',
    low2: '25.00',
    high1: '45.00',
    high2: '55.00',
    category: '温度',
    address: '氢化反应釜温度传感器',
    status: 0
  },
  {
    id: 'TE-109B-8',
    data: '-999.00',
    unit: '℃',
    low1: '35.00',
    low2: '25.00',
    high1: '45.00',
    high2: '55.00',
    category: '温度',
    address: '氢化反应釜温度传感器',
    status: 0
  },
  {
    id: 'TE-109B-9',
    data: '-999.00',
    unit: '℃',
    low1: '35.00',
    low2: '25.00',
    high1: '45.00',
    high2: '55.00',
    category: '温度',
    address: '氢化反应釜温度传感器',
    status: 0
  },
  {
    id: 'TE-109B-10',
    data: '-999.00',
    unit: '℃',
    low1: '35.00',
    low2: '25.00',
    high1: '45.00',
    high2: '55.00',
    category: '温度',
    address: '氢化反应釜温度传感器',
    status: 0
  },
]

export const dangerSourcesColumns = [
  {
    title: '现场位号',
    key: 'id',
    dataIndex: 'id'
  },
  {
    title: '实时数据',
    key: 'data',
    dataIndex: 'data'
  },
  {
    title: '单位',
    key: 'unit',
    dataIndex: 'unit'
  },
  {
    title: '低报',
    key: 'low1',
    dataIndex: 'low1',
    customRender: text => text || '-'
  },
  {
    title: '低低报',
    key: 'low2',
    dataIndex: 'low2',
    customRender: text => text || '-'
  },
  {
    title: '高报',
    key: 'high1',
    dataIndex: 'high1',
    customRender: text => text || '-'
  },
  {
    title: '高高报',
    key: 'high2',
    dataIndex: 'high2',
    customRender: text => text || '-'
  },
  {
    title: '种类',
    key: 'category',
    dataIndex: 'category'
  },
  {
    title: '地址',
    key: 'address',
    dataIndex: 'address',
    width: 90
  },
  {
    title: '报警状态',
    key: 'status',
    dataIndex: 'status',
    align: 'center',
    customRender: text => {
      // if (text === 1) {
      //   return <span style="color: #ff0000;">高高报</span>
      // } else if (text === 2) {
      //   return <span style="color: #ff0000;">高报</span>
      // } else {
      //   return '正常'
      // }
      return '正常'
    }
  },
  {
    title: '操作',
    key: 'action',
    scopedSlots: { customRender: 'action' },
  }
]

export const dangerSources = [
  [{
      "Q": 32.58757496935173,
      "R": 117.25465321674943,
      "lng": 117.254653,
      "lat": 32.587575
    },
    {
      "Q": 32.58741677294027,
      "R": 117.25496435299516,
      "lng": 117.254964,
      "lat": 32.587417
    },
    {
      "Q": 32.5871681780153,
      "R": 117.25481414929033,
      "lng": 117.254814,
      "lat": 32.587168
    },
    {
      "Q": 32.58699642111894,
      "R": 117.25512528553605,
      "lng": 117.255125,
      "lat": 32.586996
    },
    {
      "Q": 32.587620168275144,
      "R": 117.25553298130632,
      "lng": 117.255533,
      "lat": 32.58762
    },
    {
      "Q": 32.58793204022557,
      "R": 117.2549107088148,
      "lng": 117.254911,
      "lat": 32.587932
    }
  ],
  [{
      "Q": 32.5867659036371,
      "R": 117.25713954940437,
      "lng": 117.25714,
      "lat": 32.586766
    },
    {
      "Q": 32.586449507679134,
      "R": 117.25779937282203,
      "lng": 117.257799,
      "lat": 32.58645
    },
    {
      "Q": 32.58586643220262,
      "R": 117.25739167705177,
      "lng": 117.257392,
      "lat": 32.585866
    },
    {
      "Q": 32.586133110604386,
      "R": 117.25673721805214,
      "lng": 117.256737,
      "lat": 32.586133
    }
  ],
  [{
      "Q": 32.588072157385774,
      "R": 117.25822591781616,
      "lng": 117.258226,
      "lat": 32.588072
    },
    {
      "Q": 32.587199818537655,
      "R": 117.2601410150528,
      "lng": 117.260141,
      "lat": 32.5872
    },
    {
      "Q": 32.5882800710915,
      "R": 117.26085984706879,
      "lng": 117.26086,
      "lat": 32.58828
    },
    {
      "Q": 32.58912980035366,
      "R": 117.25892865657806,
      "lng": 117.258929,
      "lat": 32.58913
    }
  ],
  [{
      "Q": 32.58713653861175,
      "R": 117.26022030517458,
      "lng": 117.26022,
      "lat": 32.587137
    },
    {
      "Q": 32.5881941926198,
      "R": 117.26103569671511,
      "lng": 117.261036,
      "lat": 32.588194
    },
    {
      "Q": 32.586829183824676,
      "R": 117.2639324824512,
      "lng": 117.263932,
      "lat": 32.586829
    },
    {
      "Q": 32.585762473740736,
      "R": 117.26317073509097,
      "lng": 117.263171,
      "lat": 32.585762
    }
  ],
  [{
      "Q": 32.589202115105785,
      "R": 117.26231360301375,
      "lng": 117.262314,
      "lat": 32.589202
    },
    {
      "Q": 32.58927443205946,
      "R": 117.26238334044808,
      "lng": 117.262383,
      "lat": 32.589274
    },
    {
      "Q": 32.58931059051446,
      "R": 117.26249599322676,
      "lng": 117.262496,
      "lat": 32.589311
    },
    {
      "Q": 32.58931059051446,
      "R": 117.26263546809554,
      "lng": 117.262635,
      "lat": 32.589311
    },
    {
      "Q": 32.58925183301773,
      "R": 117.26274275645613,
      "lng": 117.262743,
      "lat": 32.589252
    },
    {
      "Q": 32.589143357537964,
      "R": 117.26280176505446,
      "lng": 117.262802,
      "lat": 32.589143
    },
    {
      "Q": 32.58901680264563,
      "R": 117.26278567180032,
      "lng": 117.262786,
      "lat": 32.589017
    },
    {
      "Q": 32.58892188635911,
      "R": 117.26271593436599,
      "lng": 117.262716,
      "lat": 32.588922
    },
    {
      "Q": 32.58887216826414,
      "R": 117.26261401042342,
      "lng": 117.262614,
      "lat": 32.588872
    },
    {
      "Q": 32.58886764843597,
      "R": 117.2625013576448,
      "lng": 117.262501,
      "lat": 32.588868
    },
    {
      "Q": 32.58891284670753,
      "R": 117.26238334044808,
      "lng": 117.262383,
      "lat": 32.588913
    },
    {
      "Q": 32.588985163894606,
      "R": 117.26232969626784,
      "lng": 117.26233,
      "lat": 32.588985
    },
    {
      "Q": 32.58909815938264,
      "R": 117.26229214534163,
      "lng": 117.262292,
      "lat": 32.589098
    }
  ],
  [{
      "Q": 32.58486476295925,
      "R": 117.2641392405331,
      "lng": 117.264139,
      "lat": 32.584865
    },
    {
      "Q": 32.58392911183692,
      "R": 117.26616162613033,
      "lng": 117.266162,
      "lat": 32.583929
    },
    {
      "Q": 32.58345450246205,
      "R": 117.2658504898846,
      "lng": 117.26585,
      "lat": 32.583455
    },
    {
      "Q": 32.58437659837471,
      "R": 117.26380664661525,
      "lng": 117.263807,
      "lat": 32.584377
    }
  ],
  [{
      "Q": 32.58382337925594,
      "R": 117.26274811953306,
      "lng": 117.262748,
      "lat": 32.583823
    },
    {
      "Q": 32.58258486805451,
      "R": 117.26535522669553,
      "lng": 117.265355,
      "lat": 32.582585
    },
    {
      "Q": 32.58225941701681,
      "R": 117.26512992113828,
      "lng": 117.26513,
      "lat": 32.582259
    },
    {
      "Q": 32.58348889251579,
      "R": 117.26251208513975,
      "lng": 117.262512,
      "lat": 32.583489
    }
  ],
  [{
      "Q": 32.58871397527214,
      "R": 117.26677143573761,
      "lng": 117.266771,
      "lat": 32.588714
    },
    {
      "Q": 32.5884201854477,
      "R": 117.2673990726471,
      "lng": 117.267399,
      "lat": 32.58842
    },
    {
      "Q": 32.58781904326744,
      "R": 117.26695919036865,
      "lng": 117.266959,
      "lat": 32.587819
    },
    {
      "Q": 32.58809927546216,
      "R": 117.26634764671326,
      "lng": 117.266348,
      "lat": 32.588099
    }
  ],
  [{
      "Q": 32.588220100466486,
      "R": 117.2680531454086,
      "lng": 117.268053,
      "lat": 32.58822
    },
    {
      "Q": 32.58789466988731,
      "R": 117.26880416393277,
      "lng": 117.268804,
      "lat": 32.587895
    },
    {
      "Q": 32.587275444550016,
      "R": 117.26836964607236,
      "lng": 117.26837,
      "lat": 32.587275
    },
    {
      "Q": 32.58760991716131,
      "R": 117.26764008522031,
      "lng": 117.26764,
      "lat": 32.58761
    }
  ],
  [{
      "Q": 32.58288242306225,
      "R": 117.26809200391176,
      "lng": 117.268092,
      "lat": 32.582882
    },
    {
      "Q": 32.58258409397966,
      "R": 117.26872500523928,
      "lng": 117.268725,
      "lat": 32.582584
    },
    {
      "Q": 32.58206427578203,
      "R": 117.26841923341158,
      "lng": 117.268419,
      "lat": 32.582064
    },
    {
      "Q": 32.58194223107267,
      "R": 117.26868745431307,
      "lng": 117.268687,
      "lat": 32.581942
    },
    {
      "Q": 32.581539934372756,
      "R": 117.26842996224764,
      "lng": 117.26843,
      "lat": 32.58154
    },
    {
      "Q": 32.58164389886144,
      "R": 117.26814564809206,
      "lng": 117.268146,
      "lat": 32.581644
    },
    {
      "Q": 32.58106531234947,
      "R": 117.26774331673982,
      "lng": 117.267743,
      "lat": 32.581065
    },
    {
      "Q": 32.58133652524186,
      "R": 117.26717468842867,
      "lng": 117.267175,
      "lat": 32.581337
    },
    {
      "Q": 32.582208921148364,
      "R": 117.2677379523218,
      "lng": 117.267738,
      "lat": 32.582209
    },
    {
      "Q": 32.58226316310058,
      "R": 117.26767894372347,
      "lng": 117.267679,
      "lat": 32.582263
    }
  ],
  [{
      "Q": 32.58263007062411,
      "R": 117.27084839746357,
      "lng": 117.270848,
      "lat": 32.58263
    },
    {
      "Q": 32.58226845846942,
      "R": 117.27165842458606,
      "lng": 117.271658,
      "lat": 32.582268
    },
    {
      "Q": 32.581943006283105,
      "R": 117.27145994111896,
      "lng": 117.27146,
      "lat": 32.581943
    },
    {
      "Q": 32.58219161569864,
      "R": 117.27096641466017,
      "lng": 117.270966,
      "lat": 32.582192
    },
    {
      "Q": 32.58233174070179,
      "R": 117.27105760976673,
      "lng": 117.271058,
      "lat": 32.582332
    },
    {
      "Q": 32.58248090578703,
      "R": 117.270746473521,
      "lng": 117.270746,
      "lat": 32.582481
    }
  ]
]

function dangerSourcesPointMarker() {
  return new AMap.Icon({
    size: new AMap.Size(30, 30),
    image: 'static/danger.png',
    imageSize: new AMap.Size(30, 30),
    imageOffset: new AMap.Pixel(0, 0)
  })
}

export const dangerSourcesPoint = [
  {
    position: [117.255141, 32.587489],
    content: '<img src="static/danger.png" class="pulsate">',
    offset: new AMap.Pixel(0, 30),
    anchor: 'bottom-center',
    zIndex: 103,
    text: '电石生产单元'
  },
  {
    position: [117.257287, 32.586332],
    icon: dangerSourcesPointMarker(),
    offset: new AMap.Pixel(-13, -30),
    zIndex: 103,
    text: '乙炔生产单元'
  },
  {
    position: [117.259546, 32.588253],
    icon: dangerSourcesPointMarker(),
    offset: new AMap.Pixel(-13, -30),
    zIndex: 103,
    text: 'PVC生产单元'
  },
  {
    position: [117.262099, 32.587051],
    content: '<img src="static/danger.png" class="pulsate">',
    offset: new AMap.Pixel(0, 30),
    anchor: 'bottom-center',
    zIndex: 103,
    text: '煤气柜单元'
  },
  {
    position: [117.26255, 32.589094],
    icon: dangerSourcesPointMarker(),
    offset: new AMap.Pixel(-13, -30),
    zIndex: 103,
    text: '冷库储存单元'
  },
  {
    position: [117.26682, 32.588303],
    icon: dangerSourcesPointMarker(),
    offset: new AMap.Pixel(-13, -30),
    zIndex: 103,
    text: '乙炔气柜'
  },
  {
    position: [117.268268, 32.587733],
    content: '<img src="static/danger.png" class="pulsate">',
    offset: new AMap.Pixel(0, 30),
    anchor: 'bottom-center',
    zIndex: 103,
    text: '电石筒仓'
  },
  {
    position: [117.26506, 32.584122],
    icon: dangerSourcesPointMarker(),
    offset: new AMap.Pixel(-13, -30),
    zIndex: 103,
    text: '氯乙烯装置'
  },
  {
    position: [117.263875, 32.583069],
    icon: dangerSourcesPointMarker(),
    offset: new AMap.Pixel(-13, -30),
    zIndex: 103,
    text: '球罐单元'
  },
  {
    position: [117.268011, 32.582038],
    icon: dangerSourcesPointMarker(),
    offset: new AMap.Pixel(-13, -30),
    zIndex: 103,
    text: '液氮储存单元'
  },
  {
    position: [117.271288, 32.582228],
    icon: dangerSourcesPointMarker(),
    offset: new AMap.Pixel(-13, -30),
    zIndex: 103,
    text: '气柜储存单元'
  },
]


// 气象
const qxData = [[117.256837,32.575746],[117.246671,32.580732],[117.251756,32.587783],[117.257314,32.595503],[117.266927,32.591146],[117.275574,32.587168],[117.270317,32.579303],[117.265296,32.571722],[117.263869,32.578345],[117.254042,32.580117]]

const qxTemp = []
qxData.forEach((position, index) => {
  qxTemp.push({
    position,
    icon: new AMap.Icon({
      size: new AMap.Size(30, 30),
      image: 'static/qx.png',
      imageSize: new AMap.Size(30, 30),
      imageOffset: new AMap.Pixel(0, 0)
    }),
    anchor: 'center',
    offset: new AMap.Pixel(0, 0),
    zIndex: 104,
    extData: {
      id: index,
      name: `南厂界${index + 1}#监测站`
    }
  })
})

export const qx = qxTemp

export const qxDetail = [
  {
    name: '二氢乙烷',
    value: '0.01'
  },
  {
    name: '氢气',
    value: '0.0'
  },
  {
    name: '氮气',
    value: '0.01'
  },
  {
    name: ' 一氧化碳',
    value: '0.24'
  },
  {
    name: 'TVOC',
    value: '0.02'
  },
  {
    name: '氧化氢',
    value: '0.0'
  }
]