/**
 * @description 表格数据计算rowSpan colSpan
 * @param {Array} data 
 * @returns []
 */
export function mergeColumns(data, name) {
  let tempIndex = 0

  data.forEach((item, index) => {
    const rowSpan = `${name}RowSpan`
    if (!item[rowSpan]) {
      item[rowSpan] = 1
    }

    if (index !== 0 && item[name] === data[tempIndex][name]) { // 当前这个跟开始合并那个开始比较，相同就累加，并且给当前项设置为0，反之重新定义开始合并的索引
      data[tempIndex][rowSpan] += 1
      item[rowSpan] = 0
    } else {
      tempIndex = index
    }
  })

  return data
}

/**
 * @description 将list转换为tree
 * @param {Array} items 
 * @returns []
 */
export function array2tree(data) {
  data.forEach(function(item) {
    delete item.children
  })
  const map = {}
  data.forEach(function(item) {
    map[item.id] = item
  })
  const val = []
  data.forEach(function(item) {
    const parent = map[item.pid]
    if (parent) {
      (parent.children || (parent.children = [])).push(item)
    } else {
      val.push(item)
    }
  })
  return val
}
