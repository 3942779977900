
// 建筑物名称
const textStyle = {
  'border-width': 0,
  'background-color': 'rgba(0,0,0,0)',
  'font-size': '16px',
  'color': '#5f6377',
  'text-shadow': '#cecece 1px 0 0, #cecece 0 1px 0, #cecece -1px 0 0, #cecece 0 -1px 0'
}
export const buildings = [
  {
    polygon: [{
        "Q": 32.5870506601744,
        "R": 117.26002836227417,
        "lng": 117.260028,
        "lat": 32.587051
      },
      {
        "Q": 32.58482683198828,
        "R": 117.2586121559143,
        "lng": 117.258612,
        "lat": 32.584827
      },
      {
        "Q": 32.58696026173041,
        "R": 117.25404167175293,
        "lng": 117.254042,
        "lat": 32.58696
      },
      {
        "Q": 32.58909364069581,
        "R": 117.2554578781128,
        "lng": 117.255458,
        "lat": 32.589094
      }
    ],
    text: {
      text: '乙炔厂',
      anchor: 'center',
      style: textStyle,
      zIndex: 101,
      position: [117.256853, 32.587159]
    }
  },
  {
    polygon: [
      {
          "Q":32.59328793263263,
          "R":117.25831174850464,
          "lng":117.258312,
          "lat":32.593288
      },
      {
          "Q":32.58974449196615,
          "R":117.26590776443481,
          "lng":117.265908,
          "lat":32.589744
      },
      {
          "Q":32.58562235410003,
          "R":117.2632040977478,
          "lng":117.263204,
          "lat":32.585622
      },
      {
          "Q":32.58929251241874,
          "R":117.25558662414551,
          "lng":117.255587,
          "lat":32.589293
      }
  ],
    text: {
      text: '电石厂',
      anchor: 'center',
      style: textStyle,
      zIndex: 101,
      position: [117.260672,32.589943]
    }
  },
  {
    polygon: [
      {
          "Q":32.58922019547964,
          "R":117.26704502105713,
          "lng":117.267045,
          "lat":32.58922
      },
      {
          "Q":32.58806311651853,
          "R":117.26949119567871,
          "lng":117.269491,
          "lat":32.588063
      },
      {
          "Q":32.58580315359538,
          "R":117.26801061630249,
          "lng":117.268011,
          "lat":32.585803
      },
      {
          "Q":32.58688794290957,
          "R":117.26552152633667,
          "lng":117.265522,
          "lat":32.586888
      }
  ],
    text: {
      text: 'PVC',
      anchor: 'center',
      style: textStyle,
      zIndex: 101,
      position: [117.267474,32.587629]
    }
  },
  {
    polygon: [
      {
          "Q":32.58665290633888,
          "R":117.27028512954712,
          "lng":117.270285,
          "lat":32.586653
      },
      {
          "Q":32.58493531269197,
          "R":117.27395439147949,
          "lng":117.273954,
          "lat":32.584935
      },
      {
          "Q":32.58153618825876,
          "R":117.27178716659546,
          "lng":117.271787,
          "lat":32.581536
      },
      {
          "Q":32.58323576658554,
          "R":117.26811790466309,
          "lng":117.268118,
          "lat":32.583236
      }
  ],
    text: {
      text: '烧碱厂',
      anchor: 'center',
      style: textStyle,
      zIndex: 101,
      position: [117.271058,32.584375]
    }
  },
  {
    polygon: [
      {
          "Q":32.58527883405419,
          "R":117.26363325119019,
          "lng":117.263633,
          "lat":32.585279
      },
      {
          "Q":32.58327192747648,
          "R":117.2679033279419,
          "lng":117.267903,
          "lat":32.583272
      },
      {
          "Q":32.58146386506209,
          "R":117.2667875289917,
          "lng":117.266788,
          "lat":32.581464
      },
      {
          "Q":32.58343465130518,
          "R":117.26247453689575,
          "lng":117.262475,
          "lat":32.583435
      }
  ],
    text: {
      text: 'VCM',
      anchor: 'center',
      style: textStyle,
      zIndex: 101,
      position: [117.265157,32.583417]
    }
  },
  {
    polygon: [
      {
          "Q":32.58209669105494,
          "R":117.26363325119019,
          "lng":117.263633,
          "lat":32.582097
      },
      {
          "Q":32.57916757323518,
          "R":117.27011346817017,
          "lng":117.270113,
          "lat":32.579168
      },
      {
          "Q":32.577630652151775,
          "R":117.26912641525269,
          "lng":117.269126,
          "lat":32.577631
      },
      {
          "Q":32.58063214410685,
          "R":117.26258182525635,
          "lng":117.262582,
          "lat":32.580632
      }
  ],
    text: {
      text: '机械动力厂',
      anchor: 'center',
      style: textStyle,
      zIndex: 101,
      position: [117.265865,32.580162]
    }
  },
  {
    polygon: [
      {
          "Q":32.58529691408944,
          "R":117.25687408447266,
          "lng":117.256874,
          "lat":32.585297
      },
      {
          "Q":32.5831272838252,
          "R":117.26146602630615,
          "lng":117.261466,
          "lat":32.583127
      },
      {
          "Q":32.579710009680774,
          "R":117.25919151306152,
          "lng":117.259192,
          "lat":32.57971
      },
      {
          "Q":32.58193396479769,
          "R":117.25459957122803,
          "lng":117.2546,
          "lat":32.581934
      }
  ],
    text: {
      text: '热电厂',
      anchor: 'center',
      style: textStyle,
      zIndex: 101,
      position: [117.257775,32.582368]
    }
  },
  {
    polygon: [
      {
          "Q":32.586580587270056,
          "R":117.25365543365479,
          "lng":117.253655,
          "lat":32.586581
      },
      {
          "Q":32.58524267397279,
          "R":117.25655221939087,
          "lng":117.256552,
          "lat":32.585243
      },
      {
          "Q":32.58202436831039,
          "R":117.25427770614624,
          "lng":117.254278,
          "lat":32.582024
      },
      {
          "Q":32.58325384703283,
          "R":117.25153112411499,
          "lng":117.251531,
          "lat":32.583254
      }
  ],
    text: {
      text: '水泥厂',
      anchor: 'center',
      style: textStyle,
      zIndex: 101,
      position: [117.254106,32.584393]
    }
  },
]

// 四色图
export const fourColor = [{
    path: [
      [117.257625, 32.587222],
      [117.257346, 32.586246],
      [117.259106, 32.586789],
      [117.258741, 32.585649]
    ],
    strokeColor: '#ff4d4f',
    strokeWeight: 1,
    strokeOpacity: 1,
    fillOpacity: 1,
    fillColor: '#ff4d4f',
    zIndex: 50
  },
  {
    color: '#faad14',
    path: [
      [117.261809, 32.58706],
      [117.261788, 32.585812],
      [117.263569, 32.586734],
      [117.263547, 32.586011]
    ],
    strokeColor: '#faad14',
    strokeWeight: 1,
    strokeOpacity: 1,
    fillOpacity: 1,
    fillColor: '#faad14',
    zIndex: 50
  },
  {
    path: [
      [117.264384, 32.583697],
      [117.265049, 32.583968],
      [117.266487, 32.583643],
      [117.266079, 32.582648]
    ],
    strokeColor: '#52c41a',
    strokeWeight: 1,
    strokeOpacity: 1,
    fillOpacity: 1,
    fillColor: '#52c41a',
    zIndex: 50
  },
  {
    path: [
      [117.262753, 32.581907],
      [117.263161, 32.580732],
      [117.265243, 32.580786],
      [117.264449, 32.581816]
    ],
    strokeColor: '#e6f7ff',
    strokeWeight: 1,
    strokeOpacity: 1,
    fillOpacity: 1,
    fillColor: '#e6f7ff',
    zIndex: 50
  }
]

// 人员
export const people = [[117.256252,32.582341], [117.260028,32.58489], [117.264878,32.588705], [117.256724,32.589048], [117.259513,32.585216], [117.257255,32.583475],[117.255372,32.584533],[117.254369,32.585216],[117.259293,32.591354],[117.25895,32.593921],[117.272018,32.585586],[117.271181,32.585008],[117.270194,32.584212],[117.267576,32.586743],[117.266203,32.587141]]

export const personDetail = [
  {
    name: '单位/部分',
    value: '热电厂'
  },
  {
    name: '姓名',
    value: '汝建伟'
  },
  {
    name: '性别',
    value: '男'
  },
  {
    name: '年龄',
    value: '38'
  },
  {
    name: '手机',
    value: '15956719989'
  },
  {
    name: '身份证号',
    value: '340602198312270617'
  },
  {
    name: '岗位',
    value: '员工'
  },
  {
    name: '账号',
    value: '214274'
  },
  {
    name: '健康状况',
    value: '正常'
  },
  {
    name: '血型',
    value: 'A'
  },
  {
    name: '文化程度',
    value: '大专'
  },
  {
    name: '工种',
    value: '苯加氧精致工'
  },
  {
    name: '风险评价',
    value: '低风险'
  },
  {
    name: '工种照片',
    value: '低风险'
  },
  {
    name: '照片',
    value: 'https://img2.baidu.com/it/u=2355776939,857101895&fm=26&fmt=auto'
  },
  {
    name: '健康码',
    value: 'https://img2.baidu.com/it/u=3857523314,3527341047&fm=253&fmt=auto&app=138&f=JPEG?w=287&h=499'
  },
  {
    name: '行程码',
    value: 'https://img2.baidu.com/it/u=4224355686,2213291443&fm=26&fmt=auto'
  },
  {
    name: '核酸证明',
    value: 'https://img2.baidu.com/it/u=412888030,3221501743&fm=15&fmt=auto'
  },
]

// 摄像头
export const camera = [[117.256445, 32.583561], [117.25622, 32.584176], [117.254761, 32.584176], [117.2546, 32.583281], [117.261976,32.582603],[117.261477,32.581671],[117.25696,32.580307],[117.250544,32.582061],[117.262281,32.588642],[117.271015,32.587105],[117.259964,32.576763],[117.256896,32.575841]]

// 车辆
export const car = [
  [[117.262303, 32.583046], [117.25417, 32.584908], [117.25402, 32.582684], [117.258848, 32.582287], [117.258784, 32.584691], [117.256896, 32.586228]],
  people,
  camera
]

export const carDetail = [
  {
    name: '所在区域',
    value: 'VCM车间-球罐单元'
  },
  {
    name: '所属单位',
    value: 'VCM车间'
  },
  {
    name: '车辆类型',
    value: '货车'
  },
  {
    name: '车牌号',
    value: '京A99SE5'
  },
  {
    name: '司机',
    value: '赵伟'
  },
  {
    name: '联系电话',
    value: '13549980953'
  },
  {
    name: '司机照片',
    value: 'https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fnews.cnhubei.com%2Fa%2F10001%2F202106%2F3b3d478b4cb802b5b25a2193cd56d90e.jpeg&refer=http%3A%2F%2Fnews.cnhubei.com&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=jpeg?sec=1643446406&t=2933ec9869886ccf59f7ba81ba431b37'
  },
  {
    name: '车辆照片',
    value: 'https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fnimg.ws.126.net%2F%3Furl%3Dhttp%253A%252F%252Fdingyue.ws.126.net%252F2021%252F0527%252F31c6f6a6p00qtq6tk008kc000dc007nc.png%26thumbnail%3D650x2147483647%26quality%3D80%26type%3Djpg&refer=http%3A%2F%2Fnimg.ws.126.net&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=jpeg?sec=1643446389&t=80ebee0ceb3f403636aacfbbb58c33df'
  }
]
